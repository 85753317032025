import React from 'react'
import NavBar from './IndexNavBar/index'
import CursoCompleto from './CursoCompleto/index'
import Professores from './Professores/index'
import AulasAbertas from './AulasAbertas/index'
import Contato from './Contato/index'
import Footer from './Footer/index'

class Home extends React.Component {
    render(){
        return(
            <>
            <NavBar/>
            <CursoCompleto/>
            <Professores/>
            <AulasAbertas/>
            <Contato/>
            <div className='footer'>
                <Footer/>
            </div>
            </>
        )
    }
}

export default Home