import React from "react"
import Carousel from './Carousel'
import '../../assets/css/style.css'

const images = {
    mosaico: require('../../assets/img/MOSAICO-OUCA.png').default,
    youtube: require('../../assets/img/YOUTUBE-OUCA.png').default,
    seta: require('../../assets/img/SETA-BRANCO.png').default
}

class QuartaSecao extends React.Component {

  render(){

        return (
            <>
            <div className='row'>
                <div className='col-md-5 col-sm-4 col-4' style={{padding: '0'}}>
                    <div className='container mosaico-position mosaico-new'>
                        <img src={images.mosaico} className='imgmosaico'/>
                        <div>
                            <a href="http://www.youtube.com/embed?listType=playlist&list=PLKgVajLr_Q-2-dM0qziej1SAJS_TJ6Hs1" target='_blank'>
                                <img src={images.youtube} className="imgyoutube" alt=""/>
                            </a>
                        </div>                        
                    </div>
                </div>
                <div className='col-md-1 col-sm-1 col-1 margin-top-depoimentos-seta'>
                    <img src={images.seta}/>
                </div>
                <div className='col-md-5 col-sm-6 col-6'>
                    <div className='container margin-top-depoimentos-escrita'>
                        CLIQUE AQUI E OUÇA DE QUEM JÁ FAZ PARTE
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default QuartaSecao;