import React from "react";
import PrimeiraSecao from './PrimeiraSecao'
import { RequestValidation } from '../../data/Service/UserService';
import { EsqueciMinhaSenha } from '../../data/Service/UserService';
import NavBar from '../IndexNavBar/index'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import '../../assets/css/style.css'
import  { Redirect } from 'react-router-dom'
import { createHashHistory } from "history";

import Button from '@material-ui/core/Button';
import swal from 'sweetalert'
import TextField from '@material-ui/core/TextField';
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";

const Loader = () => (
    <div className="divLoader">
        <svg className="svgLoader" viewBox="0 0 100 100" width="5em" height="5em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)">
                <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
            </path>
        </svg>
    </div>
);



class AlterPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailApi: '',
            nomeApi: '',
            tokenApi: '',
            senha2 : '',
            senha1: '',
            disabledCupom: false,
            submitted: false,
            validField: false,
            mensagemError: 'Estamos validando seus dados de acesso!',
            loading: false}
            this.handleEmailForgotOnChange = this.handleEmailForgotOnChange.bind(this);
            this.handleEmailForgotOnChange2 = this.handleEmailForgotOnChange2.bind(this);

            this.handleSubmitForgot = this.handleSubmitForgot.bind(this);
            this.validarToken();
            
    }
      

    handleEmailForgotOnChange(e){           
        this.setState({senha1: e.target.value})
      }

      handleEmailForgotOnChange2(e){           
        this.setState({senha2: e.target.value})
      }

    async handleSubmitForgot(e){
        e.preventDefault()
        this.setState({ loading: true })

    
        if(this.state.emailApi == ""){
          swal('Favor informar o seu E-MAIL!','','error')
        }
      
        if(this.state.senha1 == ""){
            swal('Favor informar uma senha para continuar!','','error')
          }

          if(this.state.senha1 != this.state.senha2){
            swal('As duas senhas devem ser iguais!','','error')
          }

          let data = await EsqueciMinhaSenha(this.state.emailApi, this.state.senha1, this.state.tokenApi).then(response => {
            return response;
        });
            alert(data.Mensagem);

            this.setState({ loading: false })

            window.location = "https://pianotalks.com.br/";

      }


    
    async validarToken()
    {
        this.setState({ loading: true })

        var token = window.location.href.replace("http://localhost:3000/alter-password?token=", "").replace("https://pianotalks.com.br/alter-password?token=", "").replace("https://pianotalks.gruposch.com.br/alter-password?token=", "");

        let data = await RequestValidation(token).then(response => {
            return response;
        });
        
        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
            
            if (data.Dados.Email == "" || data.Dados.Email == null || data.Dados.Email == undefined)
            {
                this.setState({mensagemError: data.Mensagem});
                swal(data.Mensagem);
            }
            else
            {
                console.log("response 3");
                this.setState({ emailApi: data.Dados.Email });
                this.setState({ tokenApi: token });
                this.setState({ nomeApi: data.Dados.Nome });
            }
    
        }

        this.setState({ loading: false })


        }

    render() {
        return (
            <> 
                
            <div id="parallax-world-of-ugg">
                    <div className="parallax-curso-completo" id='conteudo-completo-h'>
            <NavBar/>  
            {this.state.emailApi == "" ? 

<div className="col-12 self-align-left m-auto pb-5 pt-5 indexVideo" style={{ textAlign: 'center'}}>
                          
<h2 style={{color:"yellow", marginTop:"200px"}}>{this.state.mensagemError}</h2>
</div>
: 

           
               
                            <div className="col-12 self-align-left m-auto pb-5 pt-5 indexVideo" style={{ textAlign: 'center' }}>
                                <br /> <br /> <br /> <br />
{this.state.loading ? <Loader /> : null}

                                <h1 style={{color:"#fff"}}>Alteração de senha</h1>
                                <br />
                                <h4 style={{color:"yellow"}}>Nome: {this.state.nomeApi}</h4>
                                <h4 style={{color:"yellow"}}>E-mail: {this.state.emailApi}</h4>

                                <div className='container modal-signin' style={{marginTop: '15px'}}>
                              <div className="form-group-costume nome">                        
                              <div className="col-12">
                              <input 
                                      type="password"  
                                      name="senha1" 
                                      className="col-4"
                                      placeholder='* Informe sua nova senha'                               
                                      value={this.state.senha1}
                                      onChange={this.handleEmailForgotOnChange}
                                      required />
</div>
<div  className="col-12" style={{marginTop: '15px'}}>

<input 
                                      type="password"  
                                      name="senha2" 
                                      className="col-4"
                                      placeholder='* Confirme sua nova senha'                               
                                      value={this.state.senha2}
                                      onChange={this.handleEmailForgotOnChange2}
                                      required />
                              </div> </div>
                              <div className='container tenho-interesse-block text-curso' style={{marginTop: '5px'}}>
                                  <button 
                                      className="btn btn-primary button-custom-interesse" 
                                      style={{margin: 'auto 0'}}
                                      type="submit"
                                      onClick={this.handleSubmitForgot}>
                                      ALTERAR SENHA
                                  </button>                  
                              </div>
                                
                                </div>
                                </div>
            
    } 
   
   </div> </div>
            </>
        );
    }

}

export default AlterPassword;