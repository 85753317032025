import axios from 'axios';

export async function Get(url, headers) {

if(url.includes('&') == false
&& url.includes('UpcXW3435oWhj9889kl4') == false)
    url += '?token=UpcXW3435oWhj9889kl4';

    if(url.includes('&')
    && url.includes('UpcXW3435oWhj9889kl4') == false)
        url += '&token=UpcXW3435oWhj9889kl4';


    if (headers !== null && headers !== undefined) {
        return await axios.get(url, headers).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.get(url).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}

export async function Post(url, body, headers) {
    console.info("post " + JSON.stringify( body))

    if (headers !== null && headers !== undefined) {


        console.info("post 1" + JSON.stringify( body))
        return await axios.post(url, body, {
            headers: headers
          }).then(response => {
            if (response !== null && response.data.Ok === true) {
                console.info("post 2" + JSON.stringify( response))

                return response.data;

            }
            else {
                console.info("post 3" + JSON.stringify( response))

                return response.data.Mensagem;
            }
        }).catch(error => {
            console.info("post 4" + JSON.stringify( error))

            return error;
        });
    }
    else {
        return await axios.post(url, body).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}

export async function Put(url, body, headers) {
    if (headers !== null && headers !== undefined) {
        return await axios.put(url, body, headers).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.put(url, body).then(response => {
            if (response !== null && response.data.Ok === true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}

export async function Delete(url, headers) {
    if (headers !== null && headers !== undefined) {
        return await axios.delete(url, headers).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            }
        }).catch(error => {
            return error;
        });
    }
    else {
        return await axios.delete(url).then(response => {
            if (response !== null && response.data.Ok == true) {
                return response.data;
            }
            else {
                return response.data.Mensagem;
            };
        }).catch(error => {
            return error;
        });
    }
}