import React from 'react'
import NavBarLogado from './NavBarLogado/index'
import Footer from './Footer/index'

class Logado extends React.Component {
    render(){
        return(
            <>
            <NavBarLogado/>
            <div className='footer'>
                <Footer/>
            </div>
            </>
        )
    }
}

export default Logado