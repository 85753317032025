import React from "react"
import IframeComponent from './Iframe'

import '../../assets/css/style.css'

class AulasAbertas extends React.Component {

    render(){
        return (
            <>
            <div id="parallax-world-of-ugg">
                <div className="parallax-aulas-abertas" id='aulas-h'>
                    <div className='container container-aulas-abertas'>                                
                        <div className='row video-padding'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-3 col-12 video-adjust'>
                                        <IframeComponent src="https://www.youtube.com/embed?listType=playlist&list=PLKgVajLr_Q-0oslqqMRWpfgxKMLwEKbT9" height="230px" width="100%"/>
                                    </div>
                                    <div className='col-md-6 col-sm-3 col-12 content-aviso-aulas-abertas text-aulas-abertas' style={{width: '400px'}}>
                                        <div className='container titulo-aulas-abertas'>
                                            Aulas GRATUITAS E SEMANAIS no YOUTUBE (Todas as terças e quintas)
                                            <br />
                                            <p className='p-texto-aulas' style={{margin: '45px 0 0 4px', lineHeight: 'normal'}}>AULAS SEMANAIS</p>
                                            <p className='text-aulas-abertas-desc' style={{margin: '0 0 0 4px', lineHeight: 'normal'}}>Aprenda a visualizar o universo do piano popular</p>
                                            <p className='p-texto-aulas' style={{margin: '10px 0 0 4px', lineHeight: 'normal'}}>FERRAMENTAS</p>
                                            <p className='text-aulas-abertas-desc' style={{margin: '0 0 0 4px', lineHeight: 'normal'}}>Toda terça-feira uma ferramenta nova para ser utilizada de forma prática</p>
                                            <p className='p-texto-aulas' style={{margin: '10px 0 0 4px', lineHeight: 'normal'}}>REPERTÓRIO</p>
                                            <p className='text-aulas-abertas-desc' style={{margin: '0 0 0 4px', lineHeight: 'normal'}}>Toda quinta-feira uma música nova aplicando diversos conteúdos</p>                                                                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
}

export default AulasAbertas;