import { Register } from '../../data/Service/UserService';
import axios from 'axios';

const { createProxyMiddleware } = require('http-proxy-middleware')
var result = ''
var tokenCerto = ''

export const cadastro = newUser => {
    return Register(0, '', newUser.email, newUser.senha, '', '', '', '', '', '', '', '', '', '', new Date, newUser.termos_de_uso, newUser.termos_de_compra, newUser.policita_de_privacidade, newUser.status, newUser.data_atualizacao_cadastro, '', '', newUser.cupom);
}

export const getUsers = user => {
    return axios
        .post('https://backend-node.api.pianotalks.com.br/users/findUser', {            
            email: user
        }).then(res => {
            return res.data
        })
        .catch(err => {            
            return err.response.message
        })
}

export async function requestPaymentAluno(){     
    var request = require('request');

    const rawResponseAuth = await fetch("https://api.sandbox.paypal.com/v1/oauth2/token", {
    body: "grant_type=client_credentials",
    headers: {
        Accept: "application/json",
        Authorization: "Basic QWF5Sm1uZE5TbERuNEVmSWpVWDFCZHZWNk1NVDVYMXk2eEtLa2hQN3A5c0UtN3RlMkE0dVhIOWFfUVJXMHhIOEJKbk1ZZEIzY241d09NY2Q6RUNJeVJXMEdYMDNaOE45amlQa0l6RnljOWRNYzdzN3N6SU5zbHNpOS1lcThqdUVrTzVGam56Rmk2MVZjcXJYN3gxOHhiaFpsbG0xbVUwbjU=",
        "Content-Type": "application/x-www-form-urlencoded"
    },
    method: "POST"
    })
    const content = await rawResponseAuth.json();

    tokenCerto = JSON.stringify(content.access_token)

    var headerToken = tokenCerto.replace(/"/g,'').trim()

    const rawResponse = await fetch("https://api.sandbox.paypal.com/v1/payments/payment", {
        body: { 
            "intent": "sale", 
            "payer": { "payment_method": "paypal" }, 
            "application_context":{ 
                "locale": "pt-BR", 
                "brand_name": "sualoja", 
                "shipping_preference": "NO_SHIPPING", 
                "user_action": "continue" 
            }, 
            "transactions": [ 
                { 
                    "amount": { 
                        "total": "600.00", 
                        "currency": "BRL", 
                        "details": { 
                            "subtotal": "600.00" 
                        } 
                    }, 
                    "description": "CURSO COMPLETO PIANO TALKS", 
                    "custom": "informacaocustomizada", 
                    "invoice_number": "seuidunico", 
                    "payment_options": { 
                        "allowed_payment_method": "IMMEDIATE_PAY" 
                    }, 
                    "item_list": { 
                        "items": [ 
                            { 
                                "quantity": "1", 
                                "name": "CURSO COMPLETO", 
                                "price": "897.00", 
                                "currency": "BRL" 
                            }, 
                            { 
                                "quantity": "1", 
                                "name": "BLACK FRIDAY", 
                                "price": "-297.00", 
                                "currency": "BRL" 
                            } 
                        ] 
                    } 
                } 
            ], 
            "note_to_payer": "Contate-nos no (19) 99633-3841 em caso de dúvida", 
            "redirect_urls": { 
                "return_url": "http://localhost:3000/success", 
                "cancel_url": "http://localhost:3000/cancel" 
            } 
        },
        headers: {
            Authorization: "Bearer "+headerToken,
            "Content-Type": "application/json"
        },
        method: "POST"
    })
    const contentNew = await rawResponse.json();

 /*   var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
    };

var dataString = { 
                    "intent": "sale", 
                    "payer": { "payment_method": "paypal" }, 
                    "application_context":{ 
                        "locale": "pt-BR", 
                        "brand_name": "sualoja", 
                        "shipping_preference": "NO_SHIPPING", 
                        "user_action": "continue" 
                    }, 
                    "transactions": [ 
                        { 
                            "amount": { 
                                "total": "600.00", 
                                "currency": "BRL", 
                                "details": { 
                                    "subtotal": "600.00" 
                                } 
                            }, 
                            "description": "CURSO COMPLETO PIANO TALKS", 
                            "custom": "informacaocustomizada", 
                            "invoice_number": "seuidunico", 
                            "payment_options": { 
                                "allowed_payment_method": "IMMEDIATE_PAY" 
                            }, 
                            "item_list": { 
                                "items": [ 
                                    { 
                                        "quantity": "1", 
                                        "name": "CURSO COMPLETO", 
                                        "price": "897.00", 
                                        "currency": "BRL" 
                                    }, 
                                    { 
                                        "quantity": "1", 
                                        "name": "BLACK FRIDAY", 
                                        "price": "-297.00", 
                                        "currency": "BRL" 
                                    } 
                                ] 
                            } 
                        } 
                    ], 
                    "note_to_payer": "Contate-nos no (19) 99633-3841 em caso de dúvida", 
                    "redirect_urls": { 
                        "return_url": "http://localhost:3000/success", 
                        "cancel_url": "http://localhost:3000/cancel" 
                    } 
                }

        var options = {
            url: 'https://api.sandbox.paypal.com/v1/payments/payment',
            method: 'POST',
            headers: headers,
            body: dataString
        };
        
        function callback(error, response, body) {
            if (!error && response.statusCode == 200) {
            }
        }
        
        return request(options, callback);*/
}

export const requestPayment = () => {     
    var request = require('request');

    var headers = {
        'Accept': 'application/json'
    };

    var dataString = 'grant_type=client_credentials';

    var options = {
        url: 'https://api.sandbox.paypal.com/v1/oauth2/token',
        method: 'POST',
        headers: headers,
        body: dataString,
        auth: {
            'user': 'AayJmndNSlDn4EfIjUX1BdvV6MMT5X1y6xKKkhP7p9sE-7te2A4uXH9a_QRW0xH8BJnMYdB3cn5wOMcd',
            'pass': 'ECIyRW0GX03Z8N9jiPkIzFyc9dMc7s7szINslsi9-eq8juEkO5FjnzFi61VcqrX7x18xbhZllm1mU0n5'
        }
    };

    function callback(error, response, body) {
        if (!error && response.statusCode == 200) {
        }
    }

    return request(options, callback);
}

export const buy = cupom => {
    return axios.post('https://backend-node.api.pianotalks.com.br/payment-paypal/buy',{
            cupom: cupom
        }).then(res => {
            if (res.status === 201) {
                Object.entries(res.data).map(([key, value]) => {
                    if(value.rel === 'approval_url'){
                        result = value.href
                    }
                })
                window.location = result
            } else {
                window.location.location()
            }
        })
        .catch(err => {
            return err.response.message
        })
}

export const validaCupom = cupom => {
    return axios.post('https://backend-node.api.pianotalks.com.br/users/validate-cupom',{
            cupom            
        }).then(res => {
            return res.data
        })
        .catch(err => {
            return err.response.message
        })
}