import React from "react"
import './style.css'
import MaterialUIStepper from './MaterialUIStepper'
import { ListAll } from '../../data/Service/ConfigurationService';
import PaginaCompras from './ComprarCursoDetalhe'

export default class DirectPay extends React.Component {

   


    render(){
        return (
              <div className='payment-container'>
                      <MaterialUIStepper/>
                  
              </div>
        );
    }
}