import React from "react";
import '../../assets/css/style.css'

const image = {
    spotify: require('../../assets/img/icons/ICON-SPOTFY.png'),
    twitter: require('../../assets/img/icons/ICON-TWITTER.png'),
    facebook: require('../../assets/img/icons/ICON-FEICE-BRANCO.png'),
    youtube: require('../../assets/img/icons/ICON-YOUTUBE.png'),
    instagram: require('../../assets/img/icons/ICON-INSTA.png')
}

class Footer extends React.Component {  

    render(){
        return (
            <>            
                <div className='footer-content container'>
                    <div className='texto-footer row'>
                        <div className='col'>
                            <p>2020 PIANO TALKS, TODOS OS DIREITOS RESERVADOS</p>
                        </div>
                        <div className='col'>                        
                            <a className='spacing-icons' href='https://www.facebook.com/pianotalksbr/'><img src={image.facebook} alt="icone do facebook" /></a>
                            <a className='spacing-icons' href='https://www.youtube.com/channel/UCsP1ZpfNszl4hiV7INXdvWA'><img src={image.youtube} alt="icone do youtube" /></a>
                            <a className='spacing-icons' href='https://www.instagram.com/piano_talks/?hl=pt-br'><img src={image.instagram} alt="icone do instagram" /></a>
                        </div>
                    </div>
                </div>            
            </>
        );
    }
}

export default Footer;