import { ListAll } from '../Service/ConfigurationService';
import { VENDA_INDEX } from '../Types/ConfigurationType';

let AppConfiguration = {
    VENDA_INDEX: null
}

export function InitConfiguration () {
    let response = ListAll();
    let data = []
    //data = response.Dados;

    data.forEach(property => {
        SetProperty(property.Chave, property.Valor);
    });
}

export function GetAll() {
    const configurationObject = AppConfiguration;
    return configurationObject;
};

export function GetProperty(name) {
    try {
        const configurationValue = AppConfiguration[name];
        return configurationValue;
    }
    catch(error) {
        return error;
    }
}

function SetProperty(name, value) {
    try {
        var exists = AppConfiguration[name];
        
        if (exists !== null && exists !== undefined) {
            AppConfiguration[name] = value;
        }
    }
    catch (error) {
        return error;
    }
};