import React from "react"
import { Route, Link, BrowserRouter as Router } from "react-router-dom";

//Css
import '../../assets/css/style.css'

//Components
import LoginModal from '../Login/LoginModal';
import { Navbar, Nav } from 'react-bootstrap';

import { GetProperty } from "../../data/Configuration";
import { ConfigurationType } from "../../data/Types/ConfigurationType";

class IndexNavBar extends React.Component {

  loginModalRef = (obj) => {
    this.showModal = obj && obj.handleShow;
  }

  onLoginClick = () => {
    this.showModal();
  }

  returnToHome = (documentId) => {
    if (window.location.pathname !== '/') {
      window.location.pathname = '/';

      document
      .getElementById(documentId)
      .scrollIntoView({ behavior: "smooth", block: "start" });
    }
    else {
      document
        .getElementById(documentId)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  render() {
    return (
      <div>
        <LoginModal ref={this.loginModalRef} ></LoginModal>
        <Navbar collapseOnSelect id="home-menu" className={" fixed-top navbar-transparent mobile container-menu-ipad"} expand="lg" fixed="top">
          <Navbar.Brand id="navbar-brand">
            <img
              src={require("../../assets/img/logo-sem-fundo-site.png").default}
              className="mr-auto"
              onClick={(e) => {
                e.preventDefault();
                this.returnToHome('home');
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav button-responsivo" />
          <Navbar.Collapse id="responsive-navbar-nav" className="menu">
            <Nav className="mr-auto ml-auto" style={{ marginLeft: '60px !important' }}>
              <div className='menu-container'>
                <Nav.Link
                  className={"nav-link a-two active"}
                  href="#curso-completo"
                  style={{ width: 'auto' }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.returnToHome('conteudo-completo-h');
                  }}
                >
                  <p className='p-style'>CURSO COMPLETO</p>
                </Nav.Link>
              </div>
              <div className='menu-container'>
                <Nav.Link
                  className={"nav-link a-two"}
                  href="#novidades-menus"
                  style={{ width: 'auto' }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.returnToHome('professores-h');
                  }}
                >
                  <p className='p-style'>PROFESSORES</p>
                </Nav.Link>
              </div>


              <div className='menu-container'>
                <Nav.Link
                  className={"nav-link a-two"}
                  href="#depoimentos-menu"
                  style={{ width: 'auto' }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.returnToHome('aulas-h');
                  }}
                >
                  <p className='p-style'>AULAS ABERTAS</p>
                </Nav.Link>
              </div>
              <div className='menu-container'>
                <Nav.Link
                  className={"nav-link a-two"}
                  href="#conteudo-menu"
                  style={{ width: 'auto' }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.returnToHome('contato-h');
                  }}
                >
                  <p className='p-style'>CONTATO</p>
                </Nav.Link>
              </div>
              <div className='menu-container-login'>
                <Nav.Link
                  className={"nav-link a-two"}
                  href="#"
                  style={{ width: 'auto' }}
                  onClick={this.onLoginClick}
                >
                  <p className='p-style-login'>LOGIN</p>
                </Nav.Link>
              </div>
              {
                GetProperty(ConfigurationType.VENDA_INDEX) ?
                  <div className='menu-container-buy'>
                    <Link to={"adquirir-curso"} className="no-decoration">
                      <Nav.Link
                        className={"nav-link a-two"}
                        href="#conteudo-menu"
                        style={{ width: 'auto' }}
                      >
                        <p className='nav-assinar'>ASSINAR</p>
                      </Nav.Link>
                    </Link>
                  </div>
                  :
                  null
                }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default IndexNavBar;