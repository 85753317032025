import React from "react"

import '../../assets/css/style.css'

class ProfessoresConvidados extends React.Component {  

    render(){
        return (
            <>
            <div className='container professores-adjust' style={{marginTop: '60px'}}>
                <div className='content-completo-professores-convidados text-professores-convidados container'>
                    PROFESSORES CONVIDADOS
                </div>
                <div className='container' style={{marginBottom: '40px'}}>
                    <div className='row' style={{padding: '25px 0px 0px 0px'}}>
                        <div className='col-md-3 col-sm-3 col-12 professores-convidados-padding'>
                            <div className="show-cristian">
                                <div className="cristian-bg-img">
                                    <div className='container' style={{lineHeight: '1px', textAlign: 'center', color: 'white', paddingTop: '350px'}}>
                                        <h3>CRISTIAN BUDU</h3>
                                    </div>
                                </div>
                                <div className="cristian-description desc-font">
                                    <div className='container' style={{margin: '0', padding: '15px 10px 10px 10px'}}>
                                        <p>De Diadema/SP, foi aluno do 
                                            Instituto Brincante, onde 
                                            conheceu o multi-artista 
                                            Antônio Nóbrega e com quem 
                                            fez várias colaborações, 
                                            incluindo um recital completo 
                                            de música brasileira. 
                                        </p>
                                        <p>
                                            Em Boston, Cristian integrou 
                                            um quarteto especializado em 
                                            Chôro que venceu o Honors 
                                            Competition do New England 
                                            Conservatory.
                                        </p>
                                        <p>
                                            Ainda nos EUA, Cristian hospedou 
                                            saraus e eventos especiais que 
                                            inspiraram a criação do 
                                            Groupmuse 
                                            (www.groupmuse.com/about), 
                                            projeto que ganhou a parceria 
                                            da Boston Symphony Orchestra 
                                            e se tornou uma das maiores 
                                            plataformas inovadoras da 
                                            música clássica.
                                        </p>                                    
                                    </div>
                                </div>                                 
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-3 col-12 professores-convidados-padding-2'>
                            <div className="show-edu-farias">
                                <div className="edu-farias-bg-img">
                                    <div className='container' style={{lineHeight: '1px', textAlign: 'center', color: 'white', paddingTop: '350px'}}>
                                        <h3>EDUARDO FARIAS</h3>
                                    </div>
                                </div>
                                <div className="edu-farias-description desc-font">
                                    <div className='container' style={{margin: '0', padding: '15px 10px 10px 10px'}}>
                                        <p>
                                            Pianista e 
                                            multi-instrumentista, 
                                            Eduardo Farias é considerado 
                                            uma revelação da nova safra 
                                            da música e do piano 
                                            brasileiro e um dos mais 
                                            requisitados instrumentistas 
                                            da atualidade.
                                        </p>
                                        <p>
                                            Atua também como produtor 
                                            musical, arranjador, 
                                            orquestrador e professor, 
                                            lecionando vários 
                                            instrumentos e prática de 
                                            grupo.
                                        </p>
                                        <p>
                                            Vem se destacando como 
                                            instrumentista ao lado 
                                            de nomes como Leo 
                                            Gandelman, Idriss 
                                            Boudrioua, Ricardo Silveira, 
                                            Gabriel Grossi, André 
                                            Vasconcellos, dentre outros.
                                        </p>                                    
                                    </div>
                                </div>                                 
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-3 col-12 professores-convidados-padding-2'>
                            <div className="show-edu-taufic">
                                <div className="edu-taufic-bg-img">
                                    <div className='container' style={{lineHeight: '1px', textAlign: 'center', color: 'white', paddingTop: '350px'}}>
                                        <h3>EDUARDO TAUFIC</h3>
                                    </div>
                                </div>
                                <div className="edu-taufic-description desc-font">
                                    <div className='container' style={{margin: '0', padding: '15px 10px 10px 10px'}}>
                                        <p>
                                            Na música instrumental, tocou 
                                            e gravou ao lado de Arthur 
                                            Maia, Ricardo Silveira, Guinga, 
                                            Pascoal Meirelles, Zé Canuto, 
                                            Nei Conceição, Márcio Menezes, 
                                            Márcio Rezende, Fábio Costa, 
                                            Sérgio Groove, Di Steffano, 
                                            Jubileu Filho, Antônio de Pádua, 
                                            Gilberto Cabral, Monaca Barreto 
                                            e Roberto Taufic, entre outros.
                                        </p>
                                        <p>
                                            Excursionou pela Europa, 
                                            apresentando-se ao lado de 
                                            músicos como Guinga, Roberto 
                                            Taufic, Giovanna Gattuso, Silvia 
                                            Donatti e Barbara Raimondi.
                                        </p>                                            
                                        <p>
                                            Com o projeto Duo Taufic 
                                            empreendeu uma turnê 
                                            durante um período de 20 dias, 
                                            incluindo shows e 
                                            masterclasses nas cidades 
                                            de Turim, Milão, Portovenere, 
                                            Roma e Laigueglia, na Itália.
                                        </p>
                                    </div>
                                </div>                                 
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-3 col-12 professores-convidados-padding-2'>
                            <div className="show-marcus">
                                <div className="marcus-bg-img">
                                    <div className='container' style={{lineHeight: '1px', textAlign: 'center', color: 'white', paddingTop: '350px'}}>
                                        <h3>MARCUS ABJAUD</h3>
                                    </div>
                                </div>
                                <div className="marcus-description desc-font">
                                    <div className='container' style={{margin: '0', padding: '15px 10px 10px 10px'}}>
                                        <p>
                                            Natural de Belo Horizonte, Marcus Abjaud começou sua carreira como músico profissional logo aos 15 anos de idade. Já acompanhou músicos de renome nacional como Léo Gandelman, e internacional como guitarrista Mike Moreno e Toninho Horta.
                                        </p>
                                        <p>
                                        Um dos quatro vencedores da XIV Edição do  Prêmio BDMG Instrumental, reconhecido festival de música instrumental realizado em Belo Horizonte.                                           
                                        </p>
                                        <p>
                                        Além do trabalho de produtor musical, se dedica paralelamente ao seu projeto instrumental e à trabalhos de carreira do cantor Wilson Sideral e do cantor gospel Eli Soares.
                                        </p>            
                                    </div>
                                </div>                                 
                            </div>
                        </div>               
                    </div>
                </div>
            </div>

            </>
        );
    }
}

export default ProfessoresConvidados;