import { Get } from './BaseService';

const url = 'https://pianotalksapi.gruposch.com.br/api/Configuracao';

const apiToken = 'UpcXW3435oWhj9889kl4';

export async function SalesEnabled () {
   
    const endpoint = '/HabilitarVendasNova';
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };


    return await Get(url + endpoint, headers);
}

export async function Consult (key) {
   
    const endpoint = '/Consultar?chave=' + key+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(url + endpoint, headers);
}

export async function ListAll () {
    const endpoint = '/ListarTodas?token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };
    return await Get(url + endpoint, headers);
}

