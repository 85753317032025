import React from "react"
import {Modal, Form} from 'react-bootstrap'
import * as EmailValidator from 'email-validator'
import swal from 'sweetalert'
import jwt_decode from 'jwt-decode'
import { EnviarEmailSenha, Register, ValiedAccount, SearchUser } from '../../data/Service/UserService';


import '../../assets/css/style.css'
import { Redirect } from "react-router-dom"

class LoginModal extends React.Component {

  constructor(){
    super()
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    
    this.handleEmailOnChange = this.handleEmailOnChange.bind(this)
    this.handleSenhaOnChange = this.handleSenhaOnChange.bind(this)
    this.handleEmailForgotOnChange = this.handleEmailForgotOnChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleSubmitForgot = this.handleSubmitForgot.bind(this)
    this.handleLogin = this.handleLogin.bind(this)

    this.state = {
        show: false,
        emailModal: '',
        senhaModal: '',
        token: '',
        email: '',
        open: false
    }
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e){
    this.setState({open: !this.state.open})
  }

  handleShow() {
    this.setState({ show: true })
  }

  handleClose(){
    this.setState({ show: false })
  }

  handleEmailOnChange(e){           
    this.setState({emailModal: e.target.value})
  }

  handleEmailForgotOnChange(e){           
    this.setState({emailForgot: e.target.value})
  }

  handleSenhaOnChange(e){           
    this.setState({senhaModal: e.target.value})
  }

  handleLogin(){
    window.location.reload(false)
  }

  handleSubmitForgot(e){
    e.preventDefault()

    const user = {
      emailForgot: this.state.emailForgot
    }

    if(!user.emailForgot){
      swal('Favor informar o seu E-MAIL!','','error')
    }
    else{

      EnviarEmailSenha(user.emailForgot)

      swal("E-mail de recuperação de senha enviado com sucesso")

    }

  }

  onSubmit(e){
    e.preventDefault()

    const user = {
      emailModal: this.state.emailModal,
      senhaModal: this.state.senhaModal
    }

    if(user.emailModal === '' && user.senhaModal === ''){
      swal('Favor informar o seu E-MAIL e SENHA!','','error')
    }else if(user.emailModal === '' ){
      swal('Favor informar o seu E-MAIL!','','error')
    }else if(user.senhaModal === ''){
      swal('Favor informar o sua SENHA!','','error')
    }else{
      if(EmailValidator.validate(user.emailModal)){        
        SearchUser(user.emailModal, user.senhaModal).then(res => {


          if(res.Token == null){
            ValiedAccount(user.emailModal, user.senhaModal, 0)

            swal("Usuário ou senha inválido",'','info')
          }else{

            localStorage.setItem('token',res.Token)
            localStorage.setItem('password',user.senhaModal)
            localStorage.setItem('email',user.emailModal)
            const { exp } = jwt_decode(res.Token)
                localStorage.setItem('expiration-token', exp)
            localStorage.setItem('email',user.emailModal)
            ValiedAccount(user.emailModal, "****", 1)
            this.handleLogin()
          
            //this.setState({ token: localStorage.setItem('token',res.message)})
            //this.handleToken(localStorage.setItem('token',res.message))            
            //this.setState({ dealersOverallTotal: this.isAlreadyAuthenticated})
            //this.setState({ dealersOverallTotal: localStorage.setItem('token',res.message)})
          }
        })
    }
  }
}  

/*this.setState({ dealersOverallTotal: localStorage.setItem('token',res.message)})
this.setState({ dealersOverallTotal: localStorage.setItem('email',user.emailModal)})    */

isAuthenticated() {
  const token = localStorage.getItem('token')
  
  return token && token.length > 10    
}
  render(){ 
    const isAlreadyAuthenticated = this.isAuthenticated()   
    return (
      <div>
      {isAlreadyAuthenticated ? <Redirect to='/arealogada'/> : (
          <Modal 
            show={this.state.show}
            effect="fadeInDown"
            onHide={this.handleClose}                
          >
            <div className='container' style={{backgroundColor: '#000', height: '100%'}}>
                <div className='container close-button-x' />
                <div className='row'>
                  <div className='col modal-login'>
                    LOGIN
                  </div>
                </div>
                <div className='container' style={{marginBottom: 'auto'}}>
                  <Form onSubmit={this.onSubmit} style={{width: '100%'}}>
                  <div className='container modal-signin'>
                      <div className="form-group-costume nome">                        
                      <input 
                              type="text"  
                              name="emailModal" 
                              className="form-control"
                              style={{textTransform: 'lowercase'}}
                              placeholder='E-MAIL'                               
                              value={this.state.emailModal}
                              onChange={this.handleEmailOnChange}
                              required />
                      </div>
                  </div>
                  <div className='container modal-senha'>
                      <div className="form-group-costume">                        
                          <input 
                              type="password"  
                              name="senhaModal" 
                              className="form-control" 
                              placeholder='SENHA'
                              value={this.state.senhaModal}
                              onChange={this.handleSenhaOnChange}
                              required />
                      </div>
                  </div>
                  <div className='container tenho-interesse-block text-curso' style={{width: '360px', marginTop: '5px'}}>
                      <button 
                          className="btn btn-primary button-custom-interesse" 
                          style={{margin: 'auto 0'}}
                          type="submit">
                          ACESSAR
                      </button>                  
                  </div>
                  <div className='container text-center' style={{marginBottom: '25px'}}>
                      <a href="#" onClick={(e)=>this.togglePanel(e)} className='esqueci-minha-senha'>
                        ESQUECI MINHA SENHA</a>
                        {this.state.open ? (
                          <div className='container modal-signin' style={{marginTop: '15px'}}>
                              <div className="form-group-costume nome">                        
                              <input 
                                      type="text"  
                                      name="emailForgot" 
                                      className="form-control"
                                      style={{textTransform: 'lowercase'}}
                                      placeholder='E-MAIL'                               
                                      value={this.state.emailForgot}
                                      onChange={this.handleEmailForgotOnChange}
                                      required />
                              </div>
                              <div className='container tenho-interesse-block text-curso' style={{marginTop: '5px'}}>
                                  <button 
                                      className="btn btn-primary button-custom-interesse" 
                                      style={{margin: 'auto 0'}}
                                      type="submit"
                                      onClick={this.handleSubmitForgot}>
                                      ENVIAR
                                  </button>                  
                              </div>
                          </div>
                          
                          ) : null}
                  </div>              
                  </Form>
                </div>
            </div> 
        </Modal>
        )
      }
      </div>
    )
  }
}

export default LoginModal