import React from 'react';
import Button from '@material-ui/core/Button';
import { validaCupom, getUsers } from './UserFunctions'
import swal from 'sweetalert'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField';
import { PayPalButton } from 'react-paypal-button-v2';
import { EmailAtivacao, Register } from '../../data/Service/UserService';
import { ListAll } from '../../data/Service/ConfigurationService';
import Iframe from 'react-iframe'
import { Redirect } from "react-router-dom";


const Loader = () => (
    <div className="divLoader">
        <svg className="svgLoader" viewBox="0 0 100 100" width="5em" height="5em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)">
                <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
            </path>
        </svg>
    </div>
);



export default class MaterialUIStepper extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hotmart: 'false',
            payPal: 'SIM',
            mensagemCompras: '',
            hotmartLinkPagamento: '',
            mensagemAlertaCadastroUsuario: '',
            step: 1,
            data: {
                nome: '',
                cpf: '',
                telefone: '',
                email: '',
                senha1: '',
                senha2: '',
                cupom: ''
            },
            disabledCupom: false,
            submitted: false,
            isLoadingCupom: false,
            termos_de_uso: false,
            termos_de_compra: false,
            policita_de_privacidade: false,
            validField: false,
            emailValido: false,
            loading: false,
            tokenAtivacao: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCupom = this.handleCupom.bind(this)
        this.handleChange = this.handleChange.bind(this)
        
    }

    async getConfiguracoes() {
        let data = await ListAll().then(response => {
            return response;
        });

        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
            this.setState({
                hotmart: data.Dados.filter(a => a.Chave == "HOTMART")[0].Valor
            })

            this.setState({
                payPal: data.Dados.filter(a => a.Chave == "PAY_PAL")[0].Valor
            })

            this.setState({
                hotmartLinkPagamento: data.Dados.filter(a => a.Chave == "HOTMART_LINK_PAGAMENTO")[0].Valor
            })
            this.setState({
                mensagemAlertaCadastroUsuario: data.Dados.filter(a => a.Chave == "MENSAGEM_ALERTA_CADASTRO_USUARIO")[0].Valor
            })
            
            this.setState({
                mensagemCompras: data.Dados.filter(a => a.Chave == "MENSAGEM_PAGINA_PAGAMENTO")[0].Valor
            })
        }

    }


    onChange = (event) => {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        if (event.target.name === 'telefone') {
            if (!isNaN(event.target.value) && event.target.value < 99999999999)
                this.setState({ data });
        }
        else
            this.setState({ data });
    }

    submit = () => {
        this.form.submit();
    }

    /*handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }*/

    prevStep = () => {
        let { step } = this.state;
        if (step > 1) {
            step--;
        }
        this.setState({ step });
    }

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        });
    };

    handleUserVlidation = () => {
        getUsers(this.state.data.email).
            then(response => {
                if (response.success) {
                    swal(response.message, '', 'info')
                } else {
                    this.setState({ disabled: this.state.disabled })
                }
            }).
            catch(err => {
            })
    }

    nextStep = () => {
        const { state } = this;
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                let { step, flag } = this.state;

                if (step === 1) {
                    if (this.state.data.senha2 != this.state.data.senha1) {
                        this.setState({ flag: false });
                        this.setState({ step });
                        swal('As senhas não são iguais', '', 'info')
                    } else {

                        if (!this.state.termos_de_compra ||
                            !this.state.termos_de_uso ||
                            !this.state.policita_de_privacidade) {
                            this.setState({ flag: false });
                            this.setState({ step });
                        } else {

                            this.setState({ loading: true })



                            
                            Register(state.data.nome, state.data.email, state.data.senha1, state.data.cpf, state.data.telefone, this.state.data.cupom, '1.00').
                                then(response => {
                                    this.setState({ loading: false })

                                    if (response.Ok) {
                                        this.setState({ flag: true });
                                        this.setState({tokenAtivacao: response.Dados.TokenAtivacao})
                                    } else {
                                        this.setState({ flag: false });
                                        swal('Ocorreu um problema com o seu cadastro. Favor tentar novamente mais tarde. Caso o problema persistir, favor entrar em contato conosco: (19) 98445-8777', '', 'info')
                                    }

                                    if (this.state.flag) {
                                        step++
                                        this.setState({ step });
                                    } else {
                                        this.setState({ step });
                                    }
                                }).
                                catch(err => {
                                    swal('Ocorreu um problema com o seu cadastro. Favor tentar novamente mais tarde. Caso o problema persistir, favor entrar em contato conosco: (19) 98445-8777', '', 'info')

                                })
                            this.getConfiguracoes();
                            this.setState({ flag: true });
                            this.setState({ step });
                        }

                        if (this.state.flag) {
                            this.setState({ step });
                        } else {
                            this.setState({ step });
                            swal('É necessário aceitar os termos antes de prosseguir', '', 'info')
                        }

                        this.setState({ flag: true });
                        this.setState({ step });
                    }

                    if (this.state.flag) {
                        this.setState({ step });
                    } else {
                        this.setState({ step });
                    }
                }

                /*if(!this.state.flag){
                    this.setState({ step });
                }else{
                    step++
                    this.setState({ step });
                }*/
            }
        });
    }

    validatorListener = (result) => {
        this.setState({ disabled: !result });
    }

    handleCupom() {
        this.setState({ isLoadingBuy: true })
        const cupom = this.state.data.cupom
        validaCupom(cupom).
            then(result => {
                swal(result.message, '', 'info')
                if (result.code === 200) {
                    this.setState({ disabledCupom: !this.state.disabledCupom })
                } else {
                    this.setState({ disabledCupom: this.state.disabledCupom })
                }
            }).catch(err => {
            })
    }

    /*handleCheckOut(){
     //   ppp.doContinue(); 
        return false;
    }*/

    handleSubmit() {
        const { state } = this;


    }

    renderStep = () => {
        const { step, data } = this.state;
        let content = null;
        switch (step) {
            case 1:
                content = (
                    <>
                        <TextValidator
                            className="mb-6"
                            key={1}
                            name="nome"
                            label="Nome *"
                            validators={['required']}
                            errorMessages={['Campo obrigatório']}
                            value={data.nome}
                            onChange={this.onChange}
                            validatorListener={this.validatorListener}
                            style={{width:"400px"}}
                        />
                        <TextValidator
                            className="mb-6"
                            key={2}
                            name="cpf"
                            label="Cpf (somente números)*"
                            validators={['required']}
                            errorMessages={['Campo obrigatório']}
                            value={data.cpf}
                            onChange={this.onChange}
                            validatorListener={this.validatorListener}
                            style={{width:"400px"}}
                            inputProps={{
                                maxLength: 14,
                              }}
                           
                        />
                        <TextValidator
                            className="mb-6"
                            key={3}
                            name="telefone"
                            label="Telefone *"
                            type="tel"
                            validators={['required']}
                            errorMessages={['Campo obrigatório']}
                            value={data.telefone}
                            onChange={this.onChange}
                            validatorListener={this.validatorListener}
                            style={{width:"400px"}}
                        />
                        <TextValidator
                            className="mb-6"
                            key={4}
                            name="email"
                            label="E-mail *"
                            validators={['required', 'isEmail']}
                            errorMessages={['Campo obrigatório', 'E-email Inválido']}
                            value={data.email}
                            onChange={this.onChange}
                            validatorListener={this.validatorListener}
                            style={{width:"400px"}}
                        />
                        <>
                            <TextValidator
                                className="password-text mb-6"
                                key={5}
                                name="senha1"
                                label="Senha *"
                                type="password"
                                validators={['required']}
                                errorMessages={['Campo obrigatório', 'Favor informar uma senha']}
                                value={data.senha1}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                                style={{width:"400px"}}
                            />
                            <TextValidator
                                key={6}
                                name="senha2"
                                className="mb-6"
                                label="Confirme sua senha *"
                                type="password"
                                validators={['required']}
                                errorMessages={['Campo obrigatório', 'Favor confirmar sua senha']}
                                value={data.senha2}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                                style={{width:"400px"}}
                            />
                        </>
                        <br />
                        <div className="main-container-flex">
                            <div className='container-flex'>
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    name="termos_de_compra"
                                    onChange={this.handleChange}
                                    value={this.state.termos_de_compra}
                                    checked={this.state.termos_de_compra}
                                    required />
                                <a data-target="#termosDeCompraModal" data-dismiss="modal" data-toggle="modal" href="#termosDeCompraModal" style={{ color: '#d7cc75' }}>* Li e aceito os Termos de Compra</a>
                            </div>
                            <div className='container-flex'>
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    name="termos_de_uso"
                                    onChange={this.handleChange}
                                    value={this.state.termos_de_uso}
                                    checked={this.state.termos_de_uso}
                                    required />
                                <a data-target="#termosDeUsoModal" data-dismiss="modal" data-toggle="modal" href="#termosDeUsoModal" style={{ color: '#d7cc75' }}>* Li e aceito os Termos de Uso</a>
                            </div>
                            <div className='container-flex'>
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    name="policita_de_privacidade"
                                    onChange={this.handleChange}
                                    value={this.state.policita_de_privacidade}
                                    checked={this.state.policita_de_privacidade}
                                />
                                <a data-target="#termosPoliticaModal" data-dismiss="modal" data-toggle="modal" href="#termosPoliticaModal" style={{ color: '#d7cc75' }}>* Li e aceito a Política de Privacidade</a>
                            </div>
                        </div>
                        <div id="termosDeCompraModal" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">TERMOS DE COMPRA</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body" style={{ textAlign: 'justify' }}>
                                        <h6>CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS / TERMOS DE COMPRA</h6>
                                        <p className='p-termos'>
                                            <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, inscrita no CNPJ sob o n. 30.754.855/0001-79 e <strong>ALUNO</strong> têm, entre si, justo e acertado, o presente Contrato de Prestação de Serviços Educacionais à distância, através da internet (“online”), de Metodologia Própria - Original e Protegida - de Piano Popular (não erudito), que se regerá pelas cláusulas seguintes e pelas condições de preço, forma e termo de pagamento descritas no presente.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DO OBJETO DO CONTRATO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 1ª</strong> - O presente contrato tem como OBJETO, a prestação, pela <strong>PIANO TALKS</strong>, de serviços educacionais à distância, através da internet (“online”), de Metodologia Própria - Original e Protegida - de Piano Popular (não erudito), conforme grade de matérias abaixo discriminadas, a serem fornecidas pela <strong>PIANO TALKS</strong> em favor do <strong>ALUNO</strong>, através da plataforma de ensino localizada no website cujo endereço é <a href="https://www.pianotalks.com.br">www.pianotalks.com.br</a>, as quais serão oferecidas em pelo menos 08 aulas por mês, sendo que a plataforma será continuamente alimentada com novas aulas e materiais, estando tudo liberado ao <strong>ALUNO</strong> devidamente inscrito e em dia com suas mensalidades.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Primeiro</strong>: A presente contratação refere-se ao “Curso Completo Piano Talks”, cuja metodologia original e protegida foi desenvolvida pela <strong>PIANO TALKS</strong> através da analogia a uma árvore, pela caracterização de seus diversos elementos (como raízes, tronco, ramificações, etc.) que se fazem necessários ao completo domínio do instrumento piano (na modalidade popular – não erudito). Neste sentido, o “Curso Completo Piano Talks” foi desenvolvido seguindo a estrutura de uma árvore, cujas matérias foram subdivididas da seguinte forma:
                                            </p>
                                        <p className='p-termos'>
                                            a. FERRAMENTAS BÁSICAS (princípios de formação do pianista popular);<br />
                                                b. FERRAMENTAS HARMÔNICAS (recursos harmônicos para aplicação);<br />
                                                c. FERRAMENTAS MELÓDICAS (recursos melódicos para improvisação e composição);<br />
                                                d. FERRAMENTAS RÍTMICAS (recursos rítmicos);<br />
                                                e. ESCALAS (aplicações harmônicas e melódicas);<br />
                                                f. RECURSOS TEXTURAIS e LITERATURA PIANÍSTICA (aplicação de conceitos da música erudita na música popular);<br />
                                                g. REPERTÓRIO (estudos práticos de repertório nacional e internacional);<br />
                                                h. ARRANJOS AVANÇADOS – Evaldo Soares (área exclusiva de arranjos do nosso mestre Evaldo Soares).
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Segundo</strong>: Além das mencionadas aulas no parágrafo anterior, o “Curso Completo Piano Talks” também contempla outros privilégios exclusivos aos seus alunos, como:
                                            </p>
                                        <p className='p-termos'>
                                            a. Dicas dirigidas e semanais de discos (o <strong>ALUNO</strong> receberá semanalmente uma sugestão de disco para ouvir, destacando detalhes pormenorizados, faixa por faixa, da performance do pianista, tendo em vista sua correlação ao conteúdo do Curso Completo Piano Talks);<br />
                                                b. Acesso exclusivo à Comunidade Fechada Piano Talks no Facebook (espaço reservado para a discussão dos conteúdos apresentados nas aulas e esclarecimento de dúvidas, além de uma live - apresentação online ao vivo - mensal exclusiva com os alunos);<br />
                                                c. Acesso a Entrevistas com Pianistas Renomados (o <strong>ALUNO</strong> terá a acesso a entrevistas exclusivas em vídeo com pianistas renomados, nacionais e internacionais);<br />
                                                d. Acesso aos exclusivos Play-Alongs Piano Talks (arquivos em formato de áudio com baixo e bateria em canais separados, gravados por grandes músicos exclusivamente para a Plataforma Piano Talks, para possibilitar ao <strong>ALUNO</strong> estudar e praticar bem acompanhado, com som profissional e de alto nível);<br />
                                                e. Acesso e gravação aos exclusivos Collabs Piano Talks (arquivos em formato de vídeo com instrumentistas renomados, que são alunos do Curso Completo Piano Talks, e que possibilitarão ao <strong>ALUNO</strong> gravar-se junto com esses grande músicos, em formato colaborativo);<br />
                                                f. Desconto (preço especial) na contratação das aulas que compõem a “Série Pianistas” (conjunto de aulas que abordam assuntos específicos baseados em trechos de solos de grandes pianistas, com utilização de seus recursos idiomáticos em outros contextos musicais, com explicações práticas a partir de análise desses solos) que também serão disponibilizadas na Plataforma Piano Talks;<br />
                                                g. Ser informado (se assim desejar) sobre a disponibilização das aulas gratuitas e semanais preparadas pelos professores do Curso Completo Piano Talks, que incluirão ferramentas específicas a serem utilizadas de forma prática ou músicas específicas com a aplicação de diversos conteúdos.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Terceiro</strong>: os dados de acesso à plataforma que disponibiliza o “Curso Completo Piano Talks” serão enviados ao e-mail indicado pelo ALUNO em seu cadastro em até 24 (vinte e quatro) horas, a contar da respectiva confirmação do devido pagamento.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Quarto</strong>: A <strong>PIANO TALKS</strong> se reserva o direito de ajustar e modificar o conteúdo e estrutura do seu curso, no todo ou em parte, conforme seus próprios critérios, de modo que isso se reflita em constante atualização e melhoria, para o oferecimento do melhor conteúdo disponível para o <strong>ALUNO</strong> e os demais alunos, sem que isso enseje qualquer nova obrigação ou direito de ressarcimento, e sem descuidar da qualidade e quantidade ora acordadas.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DAS OBRIGAÇÕES DA PIANO TALKS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 2ª -</strong> São obrigações da <strong>PIANO TALKS</strong>:
                                            </p>
                                        <p className='p-termos'>
                                            a) fornecer acesso ao ambiente virtual em que todo o conteúdo de aulas e respectivo material didático do “Curso Completo <strong>PIANO TALKS</strong>” é disponibilizado na plataforma (conforme especificado na CLÁUSULA 1ª do presente instrumento), através de login e senha gerados pela <strong>PIANO TALKS</strong> após confirmação do devido pagamento pelo <strong>ALUNO</strong>, não podendo garantir, entretanto, a disponibilidade ininterrupta de suas aplicações, que podem vir a apresentar intercorrências temporárias em razão de questões operacionais ou técnicas, sem que tais questões ensejem qualquer direito a indenização a qualquer tempo. De qualquer forma, compromete-se a <strong>PIANO TALKS</strong> a envidar os maiores esforços e agir com rapidez e prontidão para solucionar todo e qualquer entrave que esteja ao seu alcance de solução, restabelecendo os acessos eventualmente interrompidos com a maior prontidão possível;<br />
                                                b) o acesso a que se refere a alínea a) acima terá a duração de 1 (um) ano, a contar da disponibilização ao <strong>CONTRATANTE</strong> do login e senha gerados pela <strong>CONTRATADA</strong> após a confirmação do respectivo pagamento;<br />
                                                c) coordenar administrativamente e academicamente os cursos, zelando pela sua qualidade e pelo cumprimento das metodologias de educação à distância, conforme especificado como objeto do presente Contrato;
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DAS OBRIGAÇÕES DO ALUNO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 3ª -</strong> São obrigações do <strong>ALUNO</strong>:
                                            </p>
                                        <p className='p-termos'>
                                            a) manter seus dados cadastrais atualizados e com informações verídicas, bem como zelar pela confidencialidade de seu login e senha, de forma a não permitir qualquer tipo de compartilhamento;<br />
                                                b) não reproduzir e nem compartilhar, sob qualquer forma ou veículo, o material do “Curso Completo <strong>PIANO TALKS</strong>, objeto desta contratação, no todo ou em parte, sob pena de responder civil e criminalmente perante a <strong>PIANO TALKS</strong> e terceiros, nos termos da Lei n. 9.609/98, por violação de propriedade intelectual e direitos autorais, devendo o uso deste ser feito exclusivamente no âmbito privado dentro do ambiente de ensino fornecido pela <strong>PIANO TALKS</strong> e para as finalidades próprias a que se destinam (sobretudo no que concerne à utilização exclusivamente individual pelo <strong>ALUNO</strong> para o seu aprendizado e desenvolvimento musical e harmônico, a fim de evoluir no domínio do instrumento piano em sua modalidade popular);<br />
                                                c) seguir os padrões de conduta estabelecidos e vigentes na internet, abstendo-se de: (i) violar a privacidade de outros usuários; (ii) permitir que outras pessoas utilizem seu acesso ao ambiente de treinamento; (iii) utilizar qualquer técnica de invasão ao site que viole a segurança do ambiente de treinamento e de sites relacionados; (iv) agir conscientemente para destruir arquivos ou programas do ambiente de treinamento e de sites relacionados; (v) utilizar os nomes e e-mails dos participantes do curso para fins comerciais; (vi) enviar mensagens que possam ser consideradas obscenas e fora dos padrões éticos e de bons costumes.
                                            </p>
                                        <p className='p-termos'>
                                            Parágrafo único: O <strong>ALUNO</strong> concorda que a <strong>PIANO TALKS</strong> poderá, a seu exclusivo critério, realizar o bloqueio de seu acesso à plataforma e aos cursos, sempre que tomar conhecimento de qualquer indício de ato ou conduta que possa caracterizar uma prática ilegal, violação dos Termos de Uso e/ou da Política de Privacidade do Piano Talks, ou que represente infração às obrigações acima descritas. O bloqueio preventivo será mantido, com a correspondente retenção dos valores pagos, em regra, até que (i) se constate que o ato ou conduta suspeita não caracterizou prática ilegal, com base em critérios e avaliação exclusiva da <strong>PIANO TALKS</strong>; ou (ii) por ordem judicial ou determinação das autoridades competentes. Se confirmada a natureza ilegal da conduta, o bloqueio tornar-se-á definitivo, sem direito ao estorno de quaisquer valores pagos, sem prejuízo da <strong>PIANO TALKS</strong> fazer valer seus direitos e pleitear qualquer outra reparação a que faça jus, inclusive através de quaisquer providências judiciais que sejam pertinentes ou se façam necessárias.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DO PAGAMENTO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 4ª –</strong> Como contraprestação pelos serviços que constituem o objeto deste instrumento (especificamente detalhados na CLÁUSULA 1ª acima), o <strong>ALUNO</strong> pagará à <strong>PIANO TALKS</strong> a quantia de R$ 1.200,00 (um mil e duzentos reais), através da modalidade de pagamento disponíveis na Plataforma Piano Talks no ato da compra.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DO INADIMPLEMENTO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 5ª -</strong> Caso o <strong>ALUNO</strong> não pague o valor acima, seja em parcela única ou em parcelas, seu acesso será negado ou suspenso pela <strong>CONTRATADA</strong>, sem prejuízo das demais providências jurídicas que se façam necessárias ou pertinentes, até que haja a quitação total dos valores em aberto.
                                            </p>
                                        <p className='p-termos'>
                                            Parágrafo único: Caso haja atraso no pagamento, incidirá multa de 5% do valor da parcela ou do valor integral (se pagos em parcela única), mais juros de 1% ao mês sobre o valor devido.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DOS DADOS PESSOAIS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 6ª -</strong> A <strong>PIANO TALKS</strong> fará a coleta, conservação e tratamento de dados pessoais do <strong>ALUNO</strong>, para cumprimento de obrigações legais, para cumprimento do presente contrato e ainda porque o <strong>ALUNO</strong> confere o seu consentimento para tanto.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Primeiro:</strong> A <strong>PIANO TALKS</strong> fará o tratamento de dados com as seguintes finalidades: Gestão Financeira, Fiscal e Administrativa, envio de materiais e comunicados sobre o curso, atendimento ao aluno, execução de suas obrigações contratuais.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Segundo:</strong> Os dados pessoais do <strong>ALUNO</strong> contidos no registro incluem nome completo, CPF, data de nascimento, telefone, e-mail, endereço.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Terceiro:</strong> A <strong>PIANO TALKS</strong> pode comunicar ou transferir em parte ou na sua totalidade os dados pessoais do <strong>ALUNO</strong> a entidades públicas e/ou privadas sempre que tal decorra de obrigação legal e ou seja necessário para cumprimento deste ou outros contratos ficando para tal expressamente autorizada pelo <strong>ALUNO</strong>.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Quarto:</strong> A <strong>PIANO TALKS</strong> informa desde já que dados pessoais podem ser eventualmente visualizados pela empresa que lhe presta serviços de Tecnologia da Informação (TI), para o quê o <strong>ALUNO</strong> declara sua ciência e concordância.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>Parágrafo Quinto:</strong> A <strong>PIANO TALKS</strong> vai conservar os dados do <strong>ALUNO</strong> por pelo menos o prazo necessário ao cumprimento recíproco das obrigações que as partes assumem neste instrumento.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DO PEDIDO DE CANCELAMENTO E RESCISÃO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 7ª.</strong> Por se tratar de compra online, fica garantido o direito ao pedido ao cancelamento por parte do <strong>ALUNO</strong> no prazo de 7 dias a contar da data da compra, com direito à devolução dos valores já eventualmente pagos, nos termos do art. 49 do Código de Defesa do Consumidor (CDC).
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 8ª –</strong> Ultrapassado o prazo acima, o presente instrumento poderá ser rescindido por qualquer das partes ao final do plano anual, quando o aluno deverá manifestar seu desejo de descontinuar seus estudo junto ao <strong>PIANO TALKS</strong>.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 9ª -</strong> A <strong>PIANO TALKS</strong> poderá rescindir o contrato, após deliberação interna, por inadimplência ou indisciplina do aluno <strong>ALUNO</strong>, o qual será previamente avisado do respectivo débito e da possível rescisão.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DO PRAZO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 10ª -</strong> O presente contrato terá duração de 1 (um) ano, a contar da data de confirmação do pagamento / acesso à Plataforma Piano Talks, mesmo período em que o <strong>ALUNO</strong> terá acesso a todos os benefícios do Curso Completo Piano Talks através da plataforma.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DOS <a href="https://presrepublica.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98">DIREITOS AUTORAIS</a> E DE PROPRIEDADE INTELECTUAL</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 11 -</strong> A estrutura do site, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, áudios, arquivos Play-Along Piano Talks, arquivos Collab Piano Talks, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, observados os termos da <a href="https://presrepublica.jusbrasil.com.br/legislacao/91774/codigo-de-propriedade-industrial-lei-9279-96">Lei da Propriedade Industrial</a> (Lei nº <a href="https://presrepublica.jusbrasil.com.br/legislacao/91774/codigo-de-propriedade-industrial-lei-9279-96">9.279/96</a>), <a href="https://presrepublica.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98">Lei de Direitos Autorais</a> (Lei nº <a href="https://presrepublica.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98">9.610</a>/98) e <a href="https://presrepublica.jusbrasil.com.br/legislacao/109879/lei-do-software-lei-9609-98">Lei do Software</a> (Lei nº <a href="https://presrepublica.jusbrasil.com.br/legislacao/109879/lei-do-software-lei-9609-98">9.609</a>/98), estão devidamente reservados e não poderão ser violados em hipótese alguma.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 12 -</strong> Os adquirentes dos cursos e serviços disponibilizados na Plataforma Piano Talks reconhecem e concordam, desde já, que é terminantemente proibida a reprodução, divulgação, compartilhamento, compra compartilhada com terceiros, revenda direta ou indireta, e qualquer outra ação que implique em atos atentatórios à propriedade intelectual, material e autoral da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, sob pena de cancelamento automático do acesso aos cursos e produtos, sem prejuízo das demais medidas administrativas, judiciais e criminais cabíveis.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 13 -</strong> Este Contrato não cede ou transfere ao <strong>ALUNO</strong> qualquer direito, de modo que o acesso não gera absolutamente qualquer direito de propriedade intelectual ou autoral ao usuário, exceto para uso pessoal para os fins expressos a que se propõem, conforme especificado no presente instrumento.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 14 -</strong> O uso da Plataforma pelo <strong>ALUNO</strong> é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual, material e autoral da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, puníveis nos termos da legislação aplicável.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CONDIÇÕES GERAIS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 15 -</strong> A validade deste instrumento está condicionada à realização da matrícula do aluno.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 16 -</strong> A pouca ou não-frequência do aluno nas aulas (utilização limitada ou nula das aulas e recursos do Curso) não influi na obrigatoriedade do pagamento do montante previsto na CLÁUSULA 4ª do presente instrumento, o qual permanece sendo devido independentemente do acesso integral do <strong>CONTRATANTE</strong> ao curso, ou não, pois a obrigação da <strong>CONTRATADA</strong> possui natureza de exclusiva disponibilização, em sua Plataforma, do conteúdo objeto deste Contrato, nos moldes e limites previstos neste mesmo instrumento.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 17 –</strong> Ao concretizar a compra, o <strong>CONTRATANTE</strong> concorda e aceita de forma integral e irrestrita com os Termos de Uso e a Política de Privacidade do Piano Talks, cujas versões completas podem ser acessadas no website da plataforma Piano Talks (<a href="https://www.pianotalks.com.br">www.pianotalks.com.br</a>). Recomenda-se, portanto, a atenta leitura de tais documentos antes da assinatura deste contrato, pois os mesmos fazem parte integrante da contratação em pauta.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 18 –</strong> Os serviços e funcionalidades disponibilizados através do website acima indicado são fornecidos como eles se apresentam, sem garantias expressas ou tácitas, incluindo, dentre outras, garantias de comerciabilidade, adequação a um fim específico, propriedade, não violação, e proteção contra vírus de computador ou outros códigos nocivos.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>DO FORO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>CLÁUSULA 19 -</strong> Para dirimir quaisquer controvérsias oriundas do <strong>CONTRATO</strong>, as partes tentarão composição amigável por negociação entre si, acompanhadas dos seus advogados ou não. Não havendo acordo após a negociação, as partes poderão valer-se do processo de Mediação ou Conciliação, e, para o caso de insucesso dessa nova tentativa de solução da disputa, elegem então o foro da Comarca de Campinas/SP, em detrimento de qualquer outro, por mais privilegiado que seja.
                                            </p>
                                        <p className='p-termos'>
                                            Por estarem assim justos e contratados, firmam eletronicamente o presente instrumento, após confirmarem, através de etapas digitais, a total compreensão e adesão aos termos aqui previstos.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="termosDeUsoModal" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">TERMOS DE USO</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body" style={{ textAlign: 'justify' }}>
                                        <h6>TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA PIANO TALKS</h6>
                                        <h6>(WWW.PIANOTALKS.COM.BR)</h6>
                                        <p className='p-termos'>
                                            Os produtos e serviços apresentados neste site são desenvolvidos
                                                e fornecidos pela <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>,
                                                inscrita no CNPJ sob o n. 30.754.855/0001-79, titular exclusiva
                                                da propriedade intelectual de todos os conteúdos, cursos e itens
                                                à venda, e demais ativos relacionados à plataforma “Piano Talks”
                                                hospedada no website <a href='https://www.pianotalks.com.br'>www.pianotalks.com.br</a>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>1. Do objeto</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A plataforma visa disponibilizar e comercializar (vender) conteúdos
                                            e cursos exclusivos e diferenciados de Piano Popular,  fornecendo aulas
                                            e material de altíssimo nível para interessados em aprender as melhores
                                            técnicas e ferramentas de piano que os habilitem a se desenvolver profundamente
                                            na performance do piano popular (não erudito) e evoluir em seus próprios
                                            caminhos artísticos.
                                            </p>
                                        <p className='p-termos'>
                                            A plataforma realiza a venda à distância por meio eletrônico via internet
                                            (online) do “Curso Completo Piano Talks”, desenvolvido seguindo a estrutura de
                                            uma árvore, cujas matérias foram subdivididas da seguinte forma:
                                            </p>
                                        <p className='p-termos'>
                                            a. FERRAMENTAS BÁSICAS (princípios de formação do pianista popular);<br />
                                                b. FERRAMENTAS HARMÔNICAS (recursos harmônicos para aplicação);<br />
                                                c. FERRAMENTAS MELÓDICAS (recursos melódicos para improvisação e composição);<br />
                                                d. FERRAMENTAS RÍTMICAS (recursos rítmicos);<br />
                                                e. ESCALAS (aplicações harmônicas e melódicas);<br />
                                                f. RECURSOS TEXTURAIS E LITERATURA PIANÍSTICA (aplicação de conceitos da música erudita na música popular);<br />
                                                g. REPERTÓRIO (estudos práticos de repertório nacional e internacional);<br />
                                                h. ARRANJOS AVANÇADOS – Evaldo Soares (área exclusiva de arranjos do nosso mestre Evaldo Soares).
                                            </p>
                                        <p className='p-termos'>
                                            <strong>2. Da aceitação</strong>
                                        </p>
                                        <p className='p-termos'>
                                            O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do site.
                                            </p>
                                        <p className='p-termos'>
                                            Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidade cabíveis.
                                            </p>
                                        <p className='p-termos'>
                                            A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços e itens fornecidos pela empresa. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>3. Do acesso dos usuários</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Serão utilizadas todas as soluções técnicas à disposição dos responsáveis pela plataforma para permitir o acesso ao serviço 24h/dia (vinte e quatro horas por dia), 7d/semana (sete dias por semana). No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>4. Do cadastro</strong>
                                        </p>
                                        <p className='p-termos'>
                                            O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a depender dos serviços ou produtos escolhidos, o pagamento de determinado(s) valor(es) correspondente(s).
                                            </p>
                                        <p className='p-termos'>
                                            Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se compromete com a veracidade integral de todos os dados fornecidos.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário se compromete a não informar a terceiros seus dados cadastrais e/ou de acesso à plataforma, responsabilizando-se integralmente pelo uso que deles seja feito.
                                            </p>
                                        <p className='p-termos'>
                                            Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter previamente o consentimento expresso de seus responsáveis legais para utilização da plataforma e dos serviços ou produtos, sendo de responsabilidade exclusiva dos mesmos o eventual acesso por menores de idade e por aqueles que não possuem plena capacidade civil sem a prévia autorização.
                                            </p>
                                        <p className='p-termos'>
                                            Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos disponibilizados na plataforma.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas comunicações necessárias.
                                            </p>
                                        <p className='p-termos'>
                                            Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a manutenção da referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.
                                            </p>
                                        <p className='p-termos'>
                                            Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de qualquer suspeita de uso indevido, inclusive da respectiva senha, fornecendo todas as informações e detalhes necessários.
                                            </p>
                                        <p className='p-termos'>
                                            Não será permitido ceder, vender, alugar ou transferir, de qualquer forma ou a qualquer título ou justificativa, a conta de acesso, que é pessoal e intransferível.
                                            </p>
                                        <p className='p-termos'>
                                            Caberá ao usuário assegurar que o seu equipamento seja minimamente compatível com as características técnicas que viabilizem a utilização adequada da plataforma e dos serviços, conteúdo ou produtos ali disponibilizados.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao site; o seu descadastramento será realizado o mais rapidamente possível, desde que não sejam verificados débitos em aberto, que deverão ser devidamente honrados para que o cancelamento solicitado seja efetivamente realizado.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário, ao aceitar os Termos de Uso e a Política de Privacidade, autoriza expressamente a plataforma Piano Talks a coletar, usar, armazenar, tratar, ceder ou utilizar as informações derivadas do uso dos serviços, do site e quaisquer plataformas, incluindo todas as informações preenchidas pelo usuário no momento em que realizar ou atualizar seu cadastro, além de outras expressamente descritas na Política de Privacidade que deverá ser autorizada pelo usuário.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>5. Dos serviços</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A plataforma poderá disponibilizar para o usuário um conjunto específico de funcionalidades e ferramentas para otimizar o uso dos serviços e produtos.
                                            </p>
                                        <p className='p-termos'>
                                            Na plataforma os serviços ou produtos oferecidos estão descritos e apresentados com o maior grau possível de exatidão, contendo informações sobre suas características, qualidades, quantidades, composição, preço, entre outros dados.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>6. Dos preços</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Os preços são indicados em reais. Os valores aplicados são aqueles que estão em vigor no momento do pedido.
                                            </p>
                                        <p className='p-termos'>
                                            Na contratação do serviço, a plataforma poderá solicitar as informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados, o usuário concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação. Referidos dados financeiros poderão ser armazenados para facilitar acessos e contratações futuras.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>7. Do cancelamento </strong>
                                        </p>
                                        <p className='p-termos'>
                                            O usuário poderá cancelar a contratação dos serviços de acordo com os termos que forem definidos no momento de sua contratação, podendo, ainda, cancelá-los em até 7 (sete) dias após a respectiva contratação, mediante contato com contato@pianotalks.com.br, de acordo com o art. 49 do Código de Defesa do Consumidor (Lei no. 8.078/90), respeitadas as condições e requisitos previstos no Contrato/Termos de Compra.
                                            </p>
                                        <p className='p-termos'>
                                            O serviço poderá ser cancelado imediatamente por violação dos Termos de Uso ou de outras obrigações contratuais.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>8. Dos vícios nos serviços prestados </strong>
                                        </p>
                                        <p className='p-termos'>
                                            Constatado qualquer vício nos serviços prestados, o usuário deverá entrar em contato com contato@pianotalks.com.br tão logo constate o(s) eventual(is) vício(s). Se, comprovada a falha na prestação do serviço, e no prazo máximo de 30 (trinta) dias não for possível resolver o(s) vício(s), o usuário poderá solicitar o abatimento proporcional do preço.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>9. Do suporte </strong>
                                        </p>
                                        <p className='p-termos'>
                                            Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com o suporte, através do contato@pianotalks.com.br.
                                            </p>
                                        <p className='p-termos'>
                                            Esses serviços de atendimento ao usuário estarão disponíveis nos seguintes dias e horários: segunda a sexta-feira, das 09:00h às 18:00h.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>10. Das responsabilidades </strong>
                                        </p>
                                        <p className='p-termos'>
                                            É de responsabilidade do usuário:
                                            </p>
                                        <p className='p-termos'>
                                            a) defeitos ou vícios técnicos originados no próprio sistema do usuário; <br />
                                                b) correta utilização da plataforma, dos serviços ou produtos oferecidos, prezando pela boa convivência, pelo respeito e cordialidade entre os usuários e fornecedores;<br />
                                                c) pelo cumprimento e respeito ao conjunto de regras disposto neste Termo de Condições Geral de Uso, no Contrato de Prestação de Serviços, na Política de Privacidade e na legislação nacional e internacional, inclusive de Compliance;<br />
                                                d) pela proteção aos dados de acesso à sua conta/perfil (login e senha).
                                            </p>
                                        <p className='p-termos'>
                                            É responsabilidade da plataforma Piano Talks:
                                            </p>
                                        <p className='p-termos'>
                                            a) indicar as características dos cursos oferecidos; <br />
                                                b) sanar os defeitos e vícios encontrados no serviço oferecido, desde que lhe tenha dado causa;<br />
                                                c) zelar pelas informações que forem por ela divulgadas, sendo que os comentários ou informações divulgadas por seus usuários são de inteira responsabilidade dos mesmos (os próprios usuários);<br />
                                                d) garantir a integridade dos conteúdos disponibilizados e evitar quaisquer atividades ilícitas praticadas através da sua plataforma.
                                            </p>
                                        <p className='p-termos'>
                                            A plataforma não se responsabiliza por links externos que se apresentem em seu sistema e que possam redirecionar o usuário a ambiente externo à sua rede.
                                            </p>
                                        <p className='p-termos'>
                                            Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias, preconceituosas ou ofensivas.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>11. Dos direitos autorais</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A estrutura do site, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, áudios, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98), estão devidamente reservados e não poderão ser violados em hipótese alguma.
                                            </p>
                                        <p className='p-termos'>
                                            Os adquirentes dos cursos e serviços disponibilizados na Plataforma Piano Talks reconhecem e concordam, desde já, que é terminantemente proibida a reprodução, divulgação, compartilhamento, compra compartilhada com terceiros, revenda direta ou indireta, e qualquer outra ação que implique em atos atentatórios à propriedade intelectual, material e autoral da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, sob pena de cancelamento automático do acesso aos conteúdos, cursos e produtos, sem prejuízo das demais medidas administrativas, judiciais e criminais cabíveis.
                                            </p>
                                        <p className='p-termos'>
                                            O disposto no presente Termos de Uso não cede ou transfere ao usuário direito algum, de modo que o acesso não gera absolutamente qualquer direito de propriedade intelectual ao usuário, exceto para uso pessoal destinado aos fins expressos a que cada serviço e/ou item se propõem, conforme especificado no presente instrumento.
                                            </p>
                                        <p className='p-termos'>
                                            O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual, material e autoral da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, puníveis nos termos da legislação aplicável.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>12. Das sanções</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Sem prejuízo das demais medidas legais cabíveis, a <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong> poderá, a qualquer momento, advertir, suspender ou cancelar a conta do usuário:
                                            </p>
                                        <p className='p-termos'>
                                            a) que violar qualquer dispositivo do presente Termo; <br />
                                                b) que descumprir os seus deveres de usuário; <br />
                                                c) que tiver qualquer comportamento incompatível com os preceitos de Ética e Integridade preconizados pela Plataforma Piano Talks, sobretudo se o comportamento for fraudulento, doloso ou ofenda a terceiros.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>13. Da rescisão</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A não observância das obrigações pactuadas neste Termo de Uso ou da legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão unilateral por parte da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong> e o bloqueio de todos os serviços prestados ao usuário.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>14. Das alterações</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, por parte de <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, para adequar ou modificar os serviços, bem como para atender novas exigências legais. Nesse sentido, quaisquer  alterações serão veiculadas pela plataforma através do website www.pianotalks.com.br e o usuário poderá optar por aceitar o novo conteúdo.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>15. Da política de privacidade</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Além do presente Termo, o usuário deverá consentir com as disposições contidas na respectiva Política de Privacidade, disponível na mesma plataforma, através do website: www.pianotalks.com.br
                                            </p>
                                        <p className='p-termos'>
                                            <strong>16. Do foro</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.
                                            </p>
                                        <p className='p-termos'>
                                            Para dirimir quaisquer controvérsias oriundas do presente Termo, as partes tentarão composição amigável por negociação entre si, acompanhadas dos seus advogados ou não. Não havendo acordo após a negociação, as partes poderão valer-se do processo de Mediação ou Conciliação, e, para o caso de insucesso dessa nova tentativa de solução da disputa, elegem então o foro da Comarca de Campinas/SP, em detrimento de qualquer outro, por mais privilegiado que seja.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="termosPoliticaModal" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">POLÍTICA DE PRIVACIDADE</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body" style={{ textAlign: 'justify' }}>
                                        <h6>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS PLATAFORMA PIANO TALKS</h6>
                                        <h6>WWW.PIANOTALKS.COM.BR</h6>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 1 - INFORMAÇÕES GERAIS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes da Plataforma Piano Talks através de seu website <strong>(www.pianotalks.com.br)</strong>, com a finalidade de demonstrar absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e tratamento, bem como a forma pela qual os usuários podem gerenciar ou excluir as suas informações pessoais.
                                            </p>
                                        <p className='p-termos'>
                                            Esta Política de Privacidade aplica-se a todos os usuários e visitantes do site <strong>www.pianotalks.com.br</strong> e integra os Termos e Condições Gerais de Uso do mesmo site, de titularidade da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>, inscrita no CNPJ sob o n. 30.754.855/0001-79, titular da propriedade intelectual de todo o conteúdo, cursos, itens à venda, e demais ativos relacionados à Plataforma Piano Talks hospedada no website <a href='https://www.pianotalks.com.br'>www.pianotalks.com.br</a>
                                        </p>
                                        <p className='p-termos'>
                                            O presente documento foi elaborado em conformidade com a Lei n. 13.709/18 - conhecida como LGPD (Lei Geral de Proteção de Dados Pessoais), a Lei n. 12.965/14 – conhecida como Marco Civil da Internet, bem como o Regulamento da União Européia n. 2016/6790 -  conhecido como GDPR (<i>General Data Protection Regulation</i>). Adicionalmente, este documento poderá ser atualizado em decorrência de eventuais atualizações normativas, razão pela qual se convida o usuário a consultar periodicamente esta seção.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE?</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Os dados pessoais do usuário e visitante são recebidos pela plataforma da seguinte forma:
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Quando o usuário cria uma conta/perfil no site/plataforma da Piano Talks. Ou faz contato por email, ou por WhatsApp: esses dados são os dados de identificação básicos, como nome completo, CPF, data de nascimento, telefone, e-mail, endereço. A partir deles, podemos identificar o usuário e o visitante, além de garantir uma maior segurança e bem-estar às suas necessidades. Ficam cientes os usuários e visitantes de que seu perfil na plataforma estará acessível apenas aos colaboradores e empregados da <strong>“Piano Talks”</strong> que fazem o trabalho de gestão e entrega dos produtos e serviços adquiridos nesta plataforma, bem como aos colaboradores da empresa de Tecnologia da Informação (TI) que presta serviços para a <strong>“Piano Talks”</strong>.
                                            </p>
                                        <p className='p-termos'>
                                            Quando um usuário e visitante acessa o nosso site, as informações sobre interação e acesso são coletadas pela empresa para garantir uma melhor experiência ao usuário e visitante. Estes dados podem tratar sobre as palavras-chave utilizadas em uma busca, o compartilhamento de um documento específico, comentários, visualizações de páginas, perfis, a URL de onde o usuário e visitante provêm, o navegador que utiliza e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.
                                            </p>
                                        <p className='p-termos'>
                                            Por intermédio de terceiro(s): A plataforma recebe dados de terceiro(s), como  Facebook, Instagram, Telegram e Google, quando um usuário faz login com o seu perfil de um desses sites. A utilização desses dados é autorizada previamente pelos usuários junto ao(s) terceiro(s) em questão.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 3 - QUAIS DADOS PESSOAIS RECEBEMOS SOBRE O USUÁRIO E VISITANTE?</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Os dados pessoais do usuário e visitante recebidos são os seguintes:
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Dados para a criação da conta/perfil na plataforma: nome completo, CPF, data de nascimento, telefone, e-mail, endereço.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Dados para otimização da navegação tais como: acesso a páginas, recomendações, comentários, interação com outros perfis e usuários, perfis seguidos, endereço de IP.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Mailing List: o e-mail cadastrado pelo visitante que optar por se inscrever na no Mailing List será coletado e armazenado até que o usuário solicite o descadastro.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Dados relacionados a contratos: diante da formalização do contrato de compra e venda ou de prestação de serviços entre a plataforma e o usuário e visitante poderão ser coletados e armazenados dados relativos a execução contratual, inclusive as comunicações realizadas entre a empresa e o usuário.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E VISITANTE?</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Os dados pessoais do usuário e do visitante coletados e armazenados pela plataforma têm por finalidade:
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço oferecido, facilitar; agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa; melhorar a experiência dos usuários e fornecer funcionalidades específicas a depender das características básicas do usuário.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Melhorias da plataforma: compreender como o usuário utiliza os serviços da plataforma, para ajudar no desenvolvimento de negócios e técnicas.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Anúncios: apresentar anúncios personalizados para o usuário com base nos dados fornecidos.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Comercial: os dados são usados para personalizar o conteúdo oferecido e gerar subsídio à plataforma para a melhora da qualidade no funcionamento dos serviços.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Previsão do perfil do usuário: tratamento automatizado de dados pessoais para avaliar o uso na plataforma.
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos da plataforma, exclusivo para usuários cadastrados
                                            </p>
                                        <p className='p-termos'>
                                            &middot; Dados de contrato: conferir às partes segurança jurídica e facilitar a conclusão do negócio.
                                            </p>
                                        <p className='p-termos'>
                                            O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Os dados pessoais do usuário e visitante são armazenados pela plataforma por prazo indeterminado ou durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.
                                            </p>
                                        <p className='p-termos'>
                                            Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer e possibilitar outra base legal para o tratamento, como execução de contrato, por exemplo.
                                            </p>
                                        <p className='p-termos'>
                                            Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:
                                            </p>
                                        <p className='p-termos'>
                                            I - cumprimento de obrigação legal ou regulatória pelo controlador;
                                            </p>
                                        <p className='p-termos'>
                                            II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
                                            </p>
                                        <p className='p-termos'>
                                            III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;
                                            </p>
                                        <p className='p-termos'>
                                            IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Todos os Dados Pessoais serão guardados na base de dados mantidas em servidores e “nuvens” da <strong>“Piano Talks”</strong> ou em base de dados mantidas em servidores e “nuvens” dos fornecedores de serviços contratados pela <strong>“Piano Talks”</strong>, os quais estão devidamente de acordo com a legislação de dados vigente.
                                            </p>
                                        <p className='p-termos'>
                                            A <strong>“Piano Talks”</strong> se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
                                            </p>
                                        <p className='p-termos'>
                                            A <strong>“Piano Talks”</strong> e seus fornecedores utilizam vários procedimentos de segurança para proteger a confidencialidade, segurança e integridade de seus Dados Pessoais, prevenindo a ocorrência de eventuais danos em virtude do tratamento desses dados.
                                            </p>
                                        <p className='p-termos'>
                                            Embora a <strong>“Piano Talks”</strong>  utilize medidas de segurança e monitore seu sistema para verificar vulnerabilidades e ataques para proteger seus dados pessoais contra divulgação não autorizada, mau uso ou alteração, o Usuário entende e concorda que não há garantias de que as informações não poderão ser acessadas, divulgadas, alteradas ou destruídas por violação de qualquer uma das proteções físicas, técnicas ou administrativas.
                                            </p>
                                        <p className='p-termos'>
                                            A plataforma não assume responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. A plataforma se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados pessoais.
                                            </p>
                                        <p className='p-termos'>
                                            Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei a fazê-lo ou se você violar nossos TERMOS E CONDIÇÕES GERAIS DE USO e/ou TERMOS DE COMPRA e tivermos que tomar medidas para garantir o pleno cumprimento dos mesmos e/ou para garantir execução contratual, ou ainda em caso de decisão judicial.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 7 – COMPARTILHAMENTO E DIVULGAÇÃO DOS DADOS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A <strong>“Piano Talks”</strong> poderá compartilhar os dados pessoais coletados nas seguintes situações, com o que o usuário/adquirente declara ciência e concordância:
                                            </p>
                                        <p className='p-termos'>
                                            1. Com os seus clientes e parceiros quando necessário e/ou apropriado à prestação de serviços relacionados;
                                            </p>
                                        <p className='p-termos'>
                                            2. Com as empresas e indivíduos contratados para a execução de determinadas atividades e serviços em nome da <strong>“Piano Talks”</strong>;
                                            </p>
                                        <p className='p-termos'>
                                            3. Com fornecedores e parceiros para consecução dos serviços contratados com a <strong>“Piano Talks”</strong>  (como tecnologia da informação, contabilidade, entre outros);
                                            </p>
                                        <p className='p-termos'>
                                            4. Para propósitos administrativos como: pesquisa, planejamento, desenvolvimento de serviços, segurança e gerenciamento de risco;
                                            </p>
                                        <p className='p-termos'>
                                            5. Quando necessário em decorrência de obrigação legal, contratual, determinação de autoridade competente, ou decisão judicial.
                                            </p>
                                        <p className='p-termos'>
                                            Nas hipóteses de compartilhamento de dados pessoais com terceiros, a <strong>“Piano Talks”</strong> exige que todos os sujeitos mencionados nos itens 1 a 5 utilizem os dados pessoais compartilhados de maneira consistente e de acordo com os propósitos para os quais foram coletados (ou com os quais o Usuário consentiu previamente) e de acordo com o que foi determinado por esta Política de Privacidade, outras declarações de privacidade de website ou países, e todas as leis de privacidade e proteção de dados aplicáveis.
                                            </p>
                                        <p className='p-termos'>
                                            A <strong>“Piano Talks”</strong> também poderá compartilhar e/ou divulgar os dados pessoais coletados nas seguintes situações, com o que o usuário/adquirente também declara ciência e concordância:
                                            </p>
                                        <p className='p-termos'>
                                            1. Cumprir com uma legislação que exija tal divulgação;
                                            </p>
                                        <p className='p-termos'>
                                            2. Investigar, impedir ou tomar medidas relacionadas a atividades ilegais suspeitas ou reais ou para cooperar com órgãos públicos ou para proteger a segurança nacional;
                                            </p>
                                        <p className='p-termos'>
                                            3. Execução de seus contratos;
                                            </p>
                                        <p className='p-termos'>
                                            4. Investigar e se defender contra quaisquer reivindicações ou alegações de terceiros;
                                            </p>
                                        <p className='p-termos'>
                                            5. Proteger a segurança ou a integridade dos serviços (por exemplo, o compartilhamento com empresas que estão sofrendo ameaças semelhantes);
                                            </p>
                                        <p className='p-termos'>
                                            6. Exercer ou proteger os direitos, a propriedade e a segurança da <strong>“Piano Talks”</strong> e suas empresas coligadas;
                                            </p>
                                        <p className='p-termos'>
                                            7. Proteger os direitos e a segurança pessoal de seus funcionários, usuários ou do público;
                                            </p>
                                        <p className='p-termos'>
                                            8. Em caso de venda, compra, fusão, reorganização, liquidação ou dissolução da <strong>FR SILVEIRA ENSINO DE MÚSICA (“Piano Talks”)</strong>.
                                            </p>
                                        <p className='p-termos'>
                                            A <strong>“Piano Talks”</strong> cientificará os respectivos usuários sobre eventuais demandas legais que resultem na divulgação de informações pessoais, a menos que tal cientificação seja vedada por lei ou proibida por mandado judicial ou, ainda, se a requisição for emergencial. A <strong>“Piano Talks”</strong> poderá contestar essas demandas se julgar que as solicitações são excessivas, vagas ou feitas por autoridades incompetentes.
                                            </p>
                                        <p className='p-termos'>
                                            Alguns fornecedores e/ou parceiros da <strong>“Piano Talks”</strong> podem ser localizados ou possuir instalações localizadas no exterior do Brasil. Nessas condições, os dados pessoais transferidos podem se sujeitar às leis de jurisdições nas quais o fornecedor de serviço ou suas instalações estão localizados. Neste caso, ao acessar nossos serviços e prover suas informações, você está consentindo o processamento, transferência e armazenamento desta informação em outros países.
                                            </p>
                                        <p className='p-termos'>
                                            Ao ser redirecionado para um aplicativo ou website de terceiros, você não será mais regido por essa Política de Privacidade ou pelos Termos de Uso da nossa plataforma “Piano Talks”. Não somos responsáveis pelas práticas de privacidade de outros websites e recomendamos que você leia atentamente as declarações de privacidade deles.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 08 – COOKIES OU DADOS DE NAVEGAÇÃO</strong>
                                        </p>
                                        <p className='p-termos'>

                                        </p>
                                        <p className='p-termos'>
                                            Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e visitante e que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário e visitante para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário e o visitante da nossa plataforma <strong>“Piano Talks”</strong> manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante a utilização de cookies.
                                            </p>
                                        <p className='p-termos'>
                                            O cookie persistente permanece no disco rígido do usuário e visitante depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 9 – CONSENTIMENTO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Ao utilizar os serviços e fornecer as informações pessoais na nossa Plataforma <strong>“Piano Talks”</strong>, o usuário está consentindo com a presente Política de Privacidade.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário, ao cadastrar-se, manifesta conhecer e poder exercitar seu direito de cancelar seu cadastro, acessar e atualizar seus dados pessoais, ao mesmo tempo em que garante a veracidade das informações por ele disponibilizadas.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário tem direito de retirar o seu consentimento a qualquer tempo (com exceção dos casos em que a empresa tiver outras bases legais para continuar tratando seus dados pessoais), bem como pedir esclarecimentos e informações. Para tanto deve entrar em contato com a “Piano Talks” e seu Data Protection Officer (DPO), FELIPE SILVEIRA, email contato@pianotalks.com.br.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 10 - BASES LEGAIS PARA PROCESSAMENTO</strong>
                                        </p>
                                        <p className='p-termos'>
                                            A <strong>“Piano Talks”</strong> apenas trata Dados Pessoais em situações em que está autorizada legalmente ou mediante expresso e inequívoco consentimento do usuário.
                                            </p>
                                        <p className='p-termos'>
                                            Conforme descrito na presente Política, a <strong>“Piano Talks”</strong> tem bases legais para coletar, produzir, receptar, classificar, utilizar, acessar, reproduzir, transmitir, distribuir, processar, arquivar, armazenar, eliminar, avaliar ou controlar a informação, modificar, comunicar, transferir, difundir ou extrair dados sobre o usuário.
                                            </p>
                                        <p className='p-termos'>
                                            As bases legais incluem seu consentimento (conforme detalhado acima), execução de contratos e procedimentos preliminares contratuais (em que o processamento é necessário para firmar o contrato com o usuário), e interesses legítimos.
                                            </p>
                                        <p className='p-termos'>
                                            Tais interesses incluem proteger o usuário e a <strong>“Piano Talks”</strong> de ameaças, cumprir a legislação aplicável, o exercício regular de direitos em processo judicial, administrativo ou arbitral, habilitar a realização ou administração dos negócios, incluindo controle de qualidade, relatórios e serviços oferecidos, gerenciar transações empresariais, campanhas de marketing, entender e melhorar os negócios e relacionamentos com os clientes e permitir que os usuários encontrem oportunidades econômicas.
                                            </p>
                                        <p className='p-termos'>
                                            O usuário tem o direito de negar ou retirar o consentimento fornecido à <strong>“Piano Talks”</strong>, quando esta for a única base legal para tratamento dos seus dados pessoais, podendo a <strong>“Piano Talks”</strong> encerrar a consecução de seus serviços para este usuário na hipótese de ocorrência de tal solicitação, caso também entenda que não há outra base legal que justifique a manutenção e tratamento desses dados pessoais.
                                            </p>
                                        <p className='p-termos'>
                                            Caso tenha dúvidas sobre as bases legais para coleta, tratamento e armazenamento de seus dados pessoais, entre em contato com a “Piano Talks”<strong>“Piano Talks”</strong>
                                        </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 11 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Reservamo-nos o direito de modificar essa Política de Privacidade a qualquer momento, de modo que recomendamos ao usuário que a visite com frequência, para poder revisá-la.
                                            </p>
                                        <p className='p-termos'>
                                            As alterações e esclarecimentos surtirão efeito imediatamente após sua publicação na plataforma. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário e visitante demonstra sua concordância com as novas normas.
                                            </p>
                                        <p className='p-termos'>
                                            Diante da fusão ou venda da plataforma a outra empresa, os dados dos usuários podem ser transferidos para os novos proprietários para a continuidade dos serviços oferecidos.
                                            </p>
                                        <p className='p-termos'>
                                            <strong>SEÇÃO 12 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</strong>
                                        </p>
                                        <p className='p-termos'>
                                            Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.
                                            </p>
                                        <p className='p-termos'>
                                            Os eventuais litígios deverão ser solucionados, inicialmente, por meio de negociação direta, seguida de Mediação. Caso tais tentativas auto compositivas restem infrutíferas, deverão as partes, então, submeter seus conflitos ao Poder Judiciário, para o quê elegem o foro da comarca de Campinas/SP.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                );
                break;
            case 2:
                
                content = (
                    <>
                        <div>
                            {<div className="form-group-costume" style={{ color: '#d7cc75', textAlign: 'justify' }}>
                                
                           {
                           <div 
                           dangerouslySetInnerHTML={{ __html: this.state.mensagemCompras }} />
                           }
                            </div>}
                        </div>
<div>



{this.state.hotmart.toUpperCase() === "SIM" ?

<Iframe src={this.state.hotmartLinkPagamento} width="600px" height="600px"></Iframe>
:
<div><br /></div>
        }
</div>

{
    this.state.payPal.toUpperCase() === "SIM" ?

                        <div className="pt-5 m-auto" style={{ width: "80%", textAlign: 'center' }} >
                          {/* <Iframe url="paypal.html"
        width="200px"
        height="55px"
        display="initial"
        position="relative"/> */
        
        
        // <PayPalButton amount='1.00'  
        // createSubscription={}
        
        // onSuccess={(details, data) => {
        //     EmailAtivacao(this.state.data.email, this.state.data.nome, this.state.data.senha1, this.state.tokenAtivacao).then((response) => {

        //         if (response.Ok)
        //         alert("Parabéns, seu acesso liberado com sucesso! Estamos muito felizes em ter você conosco!");
        //         else
        //             alert("Ocorreu um problema com o seu cadastro. Favor tentar novamente mais tarde. Caso o problema persistir, favor entrar em contato conosco: (19) 98445-8777");
        //     }).catch(err => {
        //         swal('Ocorreu um problema com o seu cadastro. Favor tentar novamente mais tarde. Caso o problema persistir, favor entrar em contato conosco: (19) 98445-8777', '', 'info')
        //     });
        // }} />
        
        
        <PayPalButton
        style={{shape: 'pill',
        color: 'gold',
        layout: 'horizontal',
        label: 'subscribe'}}
        options={{vault: true}}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: 'P-4FY852798B7213929MCMXSQI'
          });
        }}

        onError={(data) => {
            swal('Ocorreu um erro ao realizar seu cadastro e já estamos verificando. Se preferir, pode entrar em contato conosco: (19) 98445-8777', '', 'info')      
        }}

        onCancel={(data) => {
            swal('Parece que você cancelou o pagamento da assinatura. Caso tenha alguma dúvida, favor entrar em contato conosco: (19) 98445-8777', '', 'info')
        }}
        onApprove={(data, actions) => {
          
           let email = this.state.data.email;
           let nome = this.state.data.nome;
           let tokenAtivacao = this.state.tokenAtivacao;
           let senha1 = this.state.data.senha1;
          // Capture the funds from the transaction
          return actions.subscription.get().then(function(details) {
            // Show a success message to your buyer

            // OPTIONAL: Call your server to save the subscription
            // return fetch("/paypal-subscription-complete", {
            //   method: "post",
            //   body: JSON.stringify({
            //     orderID: data.orderID,
            //     subscriptionID: data.subscriptionID
            //   })
            // });

            EmailAtivacao(email, nome, senha1, tokenAtivacao).then((response) => {

                        if (response.Ok)
                        {
                            swal('Parabéns. Seu cadastro foi realizado com sucesso. A partir de agora, durante um ano, você terá acesso à todo conteúdo que a Piano Talks disponibilizar. Basta clicar em login e informar seus dados de acesso.', '', 'info')
                        }
                        else
                        swal('Ocorreu um erro ao realizar seu cadastro e já estamos verificando. Se preferir, pode entrar em contato conosco: (19) 98445-8777', '', 'info')
                    }).catch(err => {
                        swal('Ocorreu um erro ao realizar seu cadastro e já estamos verificando. Se preferir, pode entrar em contato conosco: (19) 98445-8777', '', 'info')                    });

          });
        }}
      />
        
        }
                        </div>
                        :
                        <div></div>
        }
                    </>
                );
                break;
            default:
                return 'Passo incorreto!';
        }
        return content;
    }

    render() {
        const { step, disabled, submitted, loading } = this.state;
        return (
            <ValidatorForm
                className="text-center mt-5 pt-5"
                ref={(r) => { this.form = r; }}
                onSubmit={this.handleSubmit}
                instantValidate
            >
                <h4 className="mt-5" style={{ fontFamily: 'Fira sans', color: '#fff'}}>Adquirir curso completo</h4>
                {this.state.loading ? <Loader /> : null}
                {this.renderStep()}
                <br />
                <br />
                <Button
                    onClick={this.prevStep}
                    style={{ marginRight: '16px' }}
                    disabled={step < 2 ? true : false}
                >
                    {step < 2 ? null : 'ANTERIOR'}
                </Button>
                {
                    step < 2 ?
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={step < 2 ? this.nextStep : null}
                            disabled={disabled || loading || submitted}
                        >
                            {
                                (step < 2 ? 'PRÓXIMO' : null)
                            }
                        </Button>
                        :
                        null
                }

                <div>

                </div>
            </ValidatorForm>



        );
    }
}