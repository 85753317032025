import axios from 'axios'
const { createProxyMiddleware } = require('http-proxy-middleware')
var result = ''

export const validate = (value) => {
    return axios.post('https://backend-node.api.pianotalks.com.br/users/validate',{
        token: value
    })
        .then(res => {
            return res.data
        })
        .catch(err => {            
            return err.response.message
        })
}

export const emailAtivacao = query => {
    return axios
        .post('https://backend-node.api.pianotalks.com.br/users/returnpayment', {            
            query: query
        }).then(res => {
            return res.data
        })
        .catch(err => {            
            return err.response.message
        })
}