import React, {Component} from 'react'
import {validate,emailAtivacao} from './UserFunctions'

class Validate extends Component {
    constructor(props){
        super(props)

        this.state = {
            message: '',
            isOk: ''
        }
    }
    componentDidMount(){ 
        const { match: { params } } = this.props;
        this.setState({isOk: this.state.isOk})
        validate(params.token)
        .then(res =>{
            this.setState({message: res.message})
            this.setState({isOk: !this.state.isOk})
        })
        .catch(err => {
            this.setState({isOk: this.state.isOk})
        })


        emailAtivacao(params.query).
        then(res=>{
        }).catch(err=>{
        })
    }
    
    render(){        
        let valueOk = this.state.isOk ? "VOLTAR":"ACESSAR A PLATAFORMA";
        return (
            <>
            <div id="parallax-world-of-ugg">
                <div className="parallax-curso-completo-image" id='home'>
                    <div className='container'>
                        <div className='container quero-ser-adquirir' style={{fontFamily: 'Fira Sans, sans-serif', fontSize: '14px'}}>                               
                            <div className='container text-curso title-validate' style={{marginTop: '150px'}}>
                                ATIVAÇÃO DE CADASTRO
                            </div>
                            <div className='container o-que-desc text-center'>
                                {this.state.message}
                            </div>
                            <div className='container o-que-desc text-center'>
                            <a href='https://frontend.pianotalks.com.br'>{valueOk}</a>
                            </div>                                      
                        </div>
                    </div>
                </div>
            </div>            
        </>
        )
    }
}

export default Validate