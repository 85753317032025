import React from "react";

import '../../assets/css/style.css'

const image = {    
    iconeArvore: require('../../assets/img/icons/ARVORE-PQ-BRANCA.png'),
    iconeDisco: require('../../assets/img/icons/DISCO-BRANCO.png'),
    iconeFacebook: require('../../assets/img/icons/FEICE-CONTORNO.png'),
    iconeColab: require('../../assets/img/icons/NOTAS-BRANCO.png')
  }
  
class SegundaSecao extends React.Component {

  render(){

        return (
            <>
            <div className='row'>
                <div className='col-md-3 col-sm-6 col-12 mb-5'>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <img src={image.iconeArvore} alt="icone da arvore" style={{width: '120px', height: '120px'}}/>
                    </div>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <hr className='hr-tree' />
                        <p className='o-que-desc text-align-custom'>
                            1. ACESSO AO CONTEÚDO DA ÁRVORE DE ESTUDOS
                        </p>
                    </div>
                </div>
                <div className='col-md-3 col-sm-6 col-12 mb-5' style={{textAlign: 'center'}}>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <img src={image.iconeDisco} alt="icone da dica de discos" />
                    </div>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <hr className='hr-tree' />
                        <p className='o-que-desc text-align-custom'>
                            2. UMA DICA DE DISCO POR SEMANA
                        </p>
                        <p className='o-que-desc-sub text-align-custom'>
                            Destacando detalhes <br /> faixa por faixa da performance do pianista
                            relacionados ao conteúdo do curso!
                            <br />
                            Muitas dicas em nossa plataforma!
                        </p>
                    </div>
                </div>
                <div className='col-md-3 col-sm-6 col-12 mb-5' style={{textAlign: 'center'}}>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <img src={image.iconeFacebook} alt="icone do facebook" style={{width: '72px', height: '72px'}}/>
                    </div>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <hr className='hr-tree' />
                        <p className='o-que-desc text-align-custom'>
                            3. ACESSO EXCLUSIVO A UMA COMUNIDADE FECHADA NO FACEBOOK
                        </p>
                        <p className='o-que-desc-sub text-align-custom'>
                            Espaço reservado para discutir os conteúdos apresentados nas aulas e tirar as suas dúvidas
                            <br />
                            <br />
                            Todo mês uma LIVE EXCLUSIVA COM ALUNOS!
                        </p>
                    </div>
                </div>
                <div className='col-md-3 col-sm-6 col-12 mb-5' style={{textAlign: 'center'}}>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <img src={image.iconeColab} alt="icone do colab" />
                    </div>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <hr className='hr-tree' />
                        <p className='o-que-desc text-align-custom'>
                            4. PLAY-ALONGS/COLABS
                        </p>
                        <p className='o-que-desc-sub text-align-custom'>
                            Você poderá estudar com grandes músicos no formato de aúdio com 
                            baixo e bateria em canais separados. <br /> <br />
                            Também será possível gravar vídeos em colabs com músicos renomados
                            que são alunos da nossa plataforma como: <br />
                            Toninho Ferragutti e outros.
                        </p>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default SegundaSecao;