import React, { Component } from 'react';

class IndexBranch extends Component {
    constructor(props){
        super(props);
        this.titleRef = React.createRef();
        this.subTitleRef = React.createRef();
    }
    
    static defaultProps = {
        branch: '',
        title: '',
        subTitle: ''
    }

    componentDidMount() {
        const { props } = this;

        this.titleRef.current.innerHTML = props.title;
        this.subTitleRef.current.innerHTML = props.subTitle;
    }

    render() {
        const { props } = this;

        return (
            <div className={props.branch}>
                <div className="text-center">
                    <p className="titulo-arvore mb-0" ref={this.titleRef} />
                    <p className="subtitulo-arvore" ref={this.subTitleRef} />
                </div>
            </div>
        );
    }
}

export default IndexBranch;