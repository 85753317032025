import React from "react";
import ContatoForm from '../Contato/contactForm'

import '../../assets/css/style.css'

const whatps = require('../../assets/img/icons/ICON-ZAP.png')
const number = 'https://wa.me/5519984458777'

class Contato extends React.Component { 
    render(){
        return (
            <>
            <div id="parallax-world-of-ugg">
                <div className="parallax-contato" id="contato-h">
                    <div className='container container-contact container-form'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-5 col-sm-12 col-11 contact-margin'>
                                    <p className='p-texto-contato'>
                                        É FÁCIL FALAR COM A GENTE!
                                    </p>
                                </div>
                                <div className='col-md-6 col-sm-12 col-12 write-contact'>
                                    <p className='p-texto-contato-desc'>
                                        Estamos aqui para responder às suas perguntas <br />
                                        e orientar seu caminho nos estudos do piano!
                                    </p>
                                </div>               
                            </div>
                        </div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12 col-12 mb-3'>
                                    <ContatoForm />
                                </div>
                                <div className='col-md-6 col-sm-12 col-12 margin-contact'>
                                    <p className='p-texto-contato-right'>&#xb7; MANDE UM E-MAIL</p>
                                    <p className='p-texto-contato-right'>&#xb7; CHAT 
                                    <a href={number} target='_blank' ><img src={whatps} /></a></p>                                    
                                    <p style={{marginBottom: '0'}} className='p-texto-contato-right'>&#xb7; LIGUE PARA NÓS</p>
                                    <p style={{marginTop: '-15px', fontSize: '30px', fontFamily: 'Fira Sans, sans-serif'}}>+55 19 9 8445 8777</p>
                                </div>               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </>
        );
    }
}

export default Contato;