import React from 'react'
import { Redirect } from "react-router-dom"
import Logado from './Logado'
import jwt_decode from 'jwt-decode'

class AreaLogada extends React.Component {

    isAuthenticated() {
        const token = localStorage.getItem('token')
        return token && token.length > 15
      }

    render(){
        const isAlreadyAuthenticated = this.isAuthenticated()
        return(
            <>
            {!isAlreadyAuthenticated ? <Redirect to={'/'}/> : 
            (<Logado/>)}
            </>
        )
    }
}

export default AreaLogada