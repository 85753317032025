import React, { Component } from 'react';
import { useLocation, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

//Routes
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';

//Components
import Home from './components/Home';
import AreaLogada from './components/AreaLogada';
import Validate from './components/Validate/index';
import Payment from './components/Purchase';
import ComprarCurso from './components/Purchase/PurchaseComprarCurso';
import AlterPassword from './components/CursoCompleto/AlterPassword';

import { InitConfiguration } from './data/Configuration';

export default class App extends Component {
    constructor() {
        super()
        this.state = { value: '' }
    }

    componentDidMount() {
        InitConfiguration();
    }

    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute exact path="/arealogada" component={AreaLogada} />
                        <PublicRoute exact path="/validate/:token" component={Validate} />
                        <PublicRoute exact path="/adquirir-curso" component={Payment} />
                        <PublicRoute exact path="/comprar-curso" component={ComprarCurso} />
                        <PublicRoute exact path="/alter-password" component={AlterPassword} />
                        <PublicRoute restricted={false} component={Home} path="/" exact />
                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}

function NoMatch() {
    let location = useLocation();

    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
                <Redirect to={'/'} />
            </h3>
        </div>
    );
}