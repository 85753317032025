import React from "react";
import '../../assets/css/style.css'

const videos = {
    dep1: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.png'),
    dep2: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.png'),
    dep3: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.png'),
    dep4: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.png'),
    dep5: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.png'),
    dep6: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.png')
}

class Carousel extends React.Component {

  render(){

        return (
            <>
            <div className="container">
                <div className="row">
                    <div className="col col-md-12 col-lg-8">
                        <div id="carousel-depoimentos" className="carousel slide width-carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carousel-depoimentos" data-slide-to="0" className="active"></li>
                                <li data-target="#carousel-depoimentos" data-slide-to="1"></li>
                                <li data-target="#carousel-depoimentos" data-slide-to="2"></li>
                                <li data-target="#carousel-depoimentos" data-slide-to="3"></li>
                                <li data-target="#carousel-depoimentos" data-slide-to="4"></li>
                                <li data-target="#carousel-depoimentos" data-slide-to="5"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video className="embed-responsive-item" controls>
                                            <source src='https://www.youtube.com/playlist?list=PLKgVajLr_Q-0oslqqMRWpfgxKMLwEKbT9' />
                                        </video>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video className="embed-responsive-item" controls>
                                            <source src={videos.dep2} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video className="embed-responsive-item" controls>
                                            <source src={videos.dep3} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video className="embed-responsive-item" controls>
                                            <source src={videos.dep4} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video className="embed-responsive-item" controls>
                                            <source src={videos.dep5} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video className="embed-responsive-item" controls>
                                            <source src={videos.dep6} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carousel-depoimentos" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carousel-depoimentos" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default Carousel;