import React from "react"
import '../../assets/css/style.css'
import IndexTree from "./IndexTree"
import TreeIndexSemDinamica from './TreeIndexSemDinamica'

class PrimeiraSecao extends React.Component {

  render(){
    const { props } = this;
        return (
            <>
            <div className='row'>
                <div className='col-md-4 col-sm-12 col-12 mb-5 content-margin' style={{padding: '0'}}>
                    <div className='container content text-curso-completo-1'>
                        O CURSO COMPLETO <br /> PIANO TALKS
                    </div>
                    <div className='col-md-12 col-sm-12 col-12' style={{marginTop: '20px'}}>
                        <div className='text-curso-desc text-center-custom'>
                            <p className='p-texto' style={{margin: '0 0 0 4px'}}>
                                O que é o Curso Completo?
                            </p>
                            <p className='p-texto-desc-arvore'>
                                A mais completa plataforma <br />interativa sobre o universo do piano!
                            </p>
                        </div>
                    </div>                    
                    <div className='col-md-12 col-sm-12 col-12' style={{marginTop: '20px'}}>
                        <div className='text-curso-desc text-center-custom'>
                            <p className='p-texto' style={{margin: '0 0 0 4px'}}>
                                Como funciona?
                            </p>
                            <p className='p-texto-desc-arvore'>
                                Organizamos as aulas numa analogia a uma árvore. <br />Nesta árvore dividimos as matérias <br />da forma como você pode observar ao lado &rarr;
                            </p>
                        </div>
                    </div>
                </div>
                {

props.ArvoreDinamica.toUpperCase() == "SIM" ?  <IndexTree /> :  <TreeIndexSemDinamica />
                }
               
            </div>
        
        
            </>
        );
    }
}

export default PrimeiraSecao;