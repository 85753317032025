import React, { Component } from "react"

//npm
import { faPlayCircle, faVideo, faFilePdf, faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//css
import '../../assets/css/style.css'

//components
import TreeHeader from "./Tree/TreeHeader";
import TreeBranch from "./Tree/TreeBranch";

//service
import { GetIndexTree } from '../../data/Service/TreeService';

const arvore = require('../../assets/img/ARVORE-BRANCA.png').default

class IndexTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: []
        }
    }

    async getIndexTree() {
        let data = await GetIndexTree(1).then(response => {
            return response;
        });

        if (data.Dados !== undefined && data.Dados != null) {
            let dados = this.organizeTree(data.Dados);

            this.setState({
                treeData: dados
            });
        }
    }

    componentDidMount() {
        this.getIndexTree();
    }

    organizeTree(data) {
        let tree = [];

        data.forEach(nivel => {
            switch (nivel.IdNivel) {
                case 1:
                    nivel.ListaArvore.forEach(branch => {
                        tree.push({
                            Ativo: branch.Ativo,
                            DataAtualizacao: branch.DataAtualizacao,
                            DataPdf: branch.DataPdf,
                            DataVideo: branch.DataVideo,
                            IdArvore: branch.IdArvore,
                            IdIdioma: branch.IdIdioma,
                            IdNivel: branch.IdNivel,
                            IdPai: branch.IdPai,
                            Idioma: branch.Idioma,
                            LinkPaginaInicial: branch.LinkPaginaInicial,
                            LinkVideo: branch.LinkVideo,
                            New: branch.New,
                            OpenModal: branch.OpenModal,
                            Nivel: branch.Nivel,
                            Ordem: branch.Ordem,
                            Pai: branch.Pai,
                            Pdf: branch.Pdf,
                            SubTitulo: branch.SubTitulo,
                            Tag: branch.Tag,
                            Titulo: branch.Titulo,
                            LinkVideoDireto: branch.LinkVideoDireto,
                            
                            Filho: []
                        });
                    });
                    break;
                case 2:
                    nivel.ListaArvore.forEach(branch => {
                        let supIndex = tree.findIndex(x => x.IdArvore === branch.IdPai);

                        if (supIndex >= 0) {
                            tree[supIndex].Filho.push({
                                Ativo: branch.Ativo,
                                DataAtualizacao: branch.DataAtualizacao,
                                DataPdf: branch.DataPdf,
                                DataVideo: branch.DataVideo,
                                IdArvore: branch.IdArvore,
                                IdIdioma: branch.IdIdioma,
                                IdNivel: branch.IdNivel,
                                IdPai: branch.IdPai,
                                Idioma: branch.Idioma,
                                LinkPaginaInicial: branch.LinkPaginaInicial,
                                LinkVideo: branch.LinkVideo,
                                New: branch.New,
                                OpenModal: branch.OpenModal,
                                Nivel: branch.Nivel,
                                Ordem: branch.Ordem,
                                Pai: branch.Pai,
                                Pdf: branch.Pdf,
                                SubTitulo: branch.SubTitulo,
                                Tag: branch.Tag,
                                Titulo: branch.Titulo,
                                LinkVideoDireto: branch.LinkVideoDireto,
                                Filho: []
                            });
                        }
                    });
                    break;
                case 3:
                    nivel.ListaArvore.forEach(branch => {
                        //1
                        tree.forEach(d => {
                            //2
                            let supIndex = d.Filho.findIndex(x => x.IdArvore === branch.IdPai);

                            if (supIndex >= 0) {
                                d.Filho[supIndex].Filho.push({
                                    Ativo: branch.Ativo,
                                    DataAtualizacao: branch.DataAtualizacao,
                                    DataPdf: branch.DataPdf,
                                    DataVideo: branch.DataVideo,
                                    IdArvore: branch.IdArvore,
                                    IdIdioma: branch.IdIdioma,
                                    IdNivel: branch.IdNivel,
                                    IdPai: branch.IdPai,
                                    Idioma: branch.Idioma,
                                    LinkPaginaInicial: branch.LinkPaginaInicial,
                                    LinkVideo: branch.LinkVideo,
                                    New: branch.New,
                                    OpenModal: branch.OpenModal,
                                    Nivel: branch.Nivel,
                                    Ordem: branch.Ordem,
                                    Pai: branch.Pai,
                                    Pdf: branch.Pdf,
                                    SubTitulo: branch.SubTitulo,
                                    Tag: branch.Tag,
                                    Titulo: branch.Titulo,
                                    LinkVideoDireto: branch.LinkVideoDireto,
                                    Filho: []
                                });
                            }
                        });
                    });
                    break;
                case 4:
                    nivel.ListaArvore.forEach(branch => {
                        //1
                        tree.forEach(d => {
                            //2
                            d.Filho.forEach(x => {
                                //3
                                let supIndex = x.Filho.findIndex(result => result.IdArvore === branch.IdPai);

                                if (supIndex >= 0) {
                                    x.Filho[supIndex].Filho.push({
                                        Ativo: branch.Ativo,
                                        DataAtualizacao: branch.DataAtualizacao,
                                        DataPdf: branch.DataPdf,
                                        DataVideo: branch.DataVideo,
                                        IdArvore: branch.IdArvore,
                                        IdIdioma: branch.IdIdioma,
                                        IdNivel: branch.IdNivel,
                                        IdPai: branch.IdPai,
                                        Idioma: branch.Idioma,
                                        LinkPaginaInicial: branch.LinkPaginaInicial,
                                        LinkVideo: branch.LinkVideo,
                                        New: branch.New,
                                        OpenModal: branch.OpenModal,
                                        Nivel: branch.Nivel,
                                        Ordem: branch.Ordem,
                                        Pai: branch.Pai,
                                        Pdf: branch.Pdf,
                                        SubTitulo: branch.SubTitulo,
                                        Tag: branch.Tag,
                                        Titulo: branch.Titulo,
                                        LinkVideoDireto: branch.LinkVideoDireto,
                                         Agrupador: branch.Agrupador,
                                        Filho: []
                                    });
                                }
                            });
                        });
                    });
                    break;
                case 5:
                    nivel.ListaArvore.forEach(branch => {
                        //1
                        tree.forEach(um => {
                            //2
                            um.Filho.forEach(dois => {
                                //3
                                dois.Filho.forEach(tres => {
                                    //4
                                    let supIndex = tres.Filho.findIndex(result => result.IdArvore === branch.IdPai);

                                    if (supIndex >= 0) {
                                        tres.Filho[supIndex].Filho.push({
                                            Ativo: branch.Ativo,
                                            DataAtualizacao: branch.DataAtualizacao,
                                            DataPdf: branch.DataPdf,
                                            DataVideo: branch.DataVideo,
                                            IdArvore: branch.IdArvore,
                                            IdIdioma: branch.IdIdioma,
                                            IdNivel: branch.IdNivel,
                                            IdPai: branch.IdPai,
                                            Idioma: branch.Idioma,
                                            LinkPaginaInicial: branch.LinkPaginaInicial,
                                            LinkVideo: branch.LinkVideo,
                                            New: branch.New,
                                            OpenModal: branch.OpenModal,
                                            Nivel: branch.Nivel,
                                            Ordem: branch.Ordem,
                                            Pai: branch.Pai,
                                            Pdf: branch.Pdf,
                                            SubTitulo: branch.SubTitulo,
                                            Tag: branch.Tag,
                                            Titulo: branch.Titulo,
                                            Agrupador: branch.Agrupador,
                                            Filho: []
                                        });
                                    }
                                });
                            });
                        });
                    });
                    break;
                case 6:
                    nivel.ListaArvore.forEach(branch => {
                        //1
                        tree.forEach(um => {
                            //2
                            um.Filho.forEach(dois => {
                                //3
                                dois.Filho.forEach(tres => {
                                    //4
                                    tres.Filho.forEach(quatro => {
                                        //5
                                        let supIndex = quatro.Filho.findIndex(result => result.IdArvore === branch.IdPai);

                                        if (supIndex >= 0) {
                                            quatro.Filho[supIndex].Filho.push({
                                                Ativo: branch.Ativo,
                                                DataAtualizacao: branch.DataAtualizacao,
                                                DataPdf: branch.DataPdf,
                                                DataVideo: branch.DataVideo,
                                                IdArvore: branch.IdArvore,
                                                IdIdioma: branch.IdIdioma,
                                                IdNivel: branch.IdNivel,
                                                IdPai: branch.IdPai,
                                                Idioma: branch.Idioma,
                                                LinkPaginaInicial: branch.LinkPaginaInicial,
                                                LinkVideo: branch.LinkVideo,
                                                New: branch.New,
                                                OpenModal: branch.OpenModal,
                                                Nivel: branch.Nivel,
                                                Ordem: branch.Ordem,
                                                Pai: branch.Pai,
                                                Pdf: branch.Pdf,
                                                SubTitulo: branch.SubTitulo,
                                                Tag: branch.Tag,
                                                Titulo: branch.Titulo,
                                                Agrupador: branch.Agrupador,
                                                Filho: []
                                            });
                                        }
                                    });
                                });
                            });
                        });
                    });
            }
        });

        return tree;
    }

    render() {
        const { state } = this;
        let count = 0;

        return (
            <>
                <div className="col-md-8 col-sm-12 col-12 " style={{ padding: '0px' }}>
                    <div className="arvorenew">
                        <img src={arvore} className="imgarvore" alt="" />
                        {
                            state.treeData.map(branch => {
                                count += 1;
                                if (branch.Ativo === true) {
                                    return (
                                        <TreeBranch
                                            key={"tree" + branch.IdArvore}
                                            title={branch.Titulo}
                                            newContent={branch.New}
                                            openModal={branch.OpenModal}
                                            text={branch.SubTitulo}
                                            branchNumber={count}
                                            child={branch.Filho}
                                            ariaBabelledby={"dropdownMenuLink" + count} />
                                    );
                                }
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}


export default IndexTree;