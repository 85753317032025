import React, { Component } from 'react';
import NavBar from '../IndexNavBar/index';
import DirectPay from '../Payment/DirectPayComprarCurso';

class PurchaseComprarCurso extends Component {

    render() {
        return (
            <>
                <NavBar />
                <div className="parallax-curso-completo heigthfull">
                    <div className="container">
                        <DirectPay />
                    </div>
                </div>
            </>
        );
    }
}

export default PurchaseComprarCurso;