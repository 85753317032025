import React from 'react'
import * as EmailValidator from 'email-validator'
import swal from 'sweetalert'
import emailjs from 'emailjs-com'

import '../../assets/css/style.css'

const serviceId= 'service_f98elgm'
const userId= 'user_uCmbaoLhRBd3d7Xd0EEWe'
const templateId= 'template_4r7ov8c'

class ContatoForm extends React.Component {      
    constructor(){
        super()
        this.state = {
            nomeForm: '',
            emailForm: '',
            mensagemForm: '',
            isLoading: false,
            show: false
        }
    
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
      }          

      onChange(e){           
          this.setState({[e.target.name]: e.target.value})
      }
    
      onSubmit(e){
          e.preventDefault()
    
          const user = {
            nomeForm: this.state.nomeForm,
            emailForm: this.state.emailForm,
            mensagemForm: this.state.mensagemForm
          }
    
          if(user.nomeForm === '' && user.emailForm === '' && user.mensagemForm){
            swal('Favor preencher todos os campos!','','error')
          }else if(user.nomeForm === '' ){
            swal('Favor informar o seu NOME!','','error')
          }else if(user.emailForm === ''){
            swal('Favor informar o seu E-MAIL!','','error')
          }else if(user.mensagemForm === ''){
            swal('Favor escrever ao menos 30 caracteres de mensagem!','','error')
          }else{
            if(EmailValidator.validate(user.emailForm)){  
                this.setState({ isLoading: true })
                emailjs.sendForm(serviceId, templateId, e.target, userId)
                .then((result) => {
                  swal('Email enviado com sucesso!','','success')
                    this.setState({
                        nomeForm: '',
                        emailForm: '',
                        mensagemForm: '',
                        isLoading: false,
                        show: false
                    })                  
                }, (error) => {
                  swal('Erro ao enviar e-mail de contato! Favor verificar os dados inseridos!', '','error')
                  this.setState({
                    isLoading: false,
                    show: false
                  })
                });
              /*sendMail(user).then(res => {
                if(res.code === 999){
                  swal('Erro ao enviar e-mail de contato! Erro: '+res.error,'','error')
                  this.setState({
                    nomeForm: '',
                    emailForm: '',
                    mensagemForm: '',
                    isLoading: false,
                    show: false
                  })
                }else if(res.code === 299){
                    swal('Email enviado com sucesso!','','success')
                    this.setState({
                        nomeForm: '',
                        emailForm: '',
                        mensagemForm: '',
                        isLoading: false,
                        show: false
                    })
                }
              })*/
            }else{
                swal('O e-mail '+user.emailForm+' é inválido! Favor verificar!','','error')
            }
        }
      }
  render(){
    return (
        <div>
            <div className='container' style={{fontFamily: 'Fira Sans, sans-serif', fontSize: '14px'}}>
            <form id='contact-form' noValidate onSubmit={this.onSubmit}>
                <div className="form-group-costume">
                    <input 
                        type="text" 
                        name="nomeForm"
                        className="form-control" 
                        placeholder='NOME' 
                        value={this.state.nomeForm}
                        onChange={this.onChange}
                        required/>
                </div>
                <div className="form-group-costume" style={{marginTop: '10px'}}>                    
                    <input 
                        type="email" 
                        name="emailForm" 
                        className="form-control" 
                        placeholder='E-MAIL'
                        style={{textTransform: 'lowercase'}}
                        value={this.state.emailForm}
                        onChange={this.onChange}
                        required/>
                </div>
                <div className="mb-3 form-group-costume" style={{marginBottom: '3px !important', marginTop: '10px'}}>                    
                    <textarea 
                        name='mensagemForm' 
                        className="form-control textarea" 
                        placeholder='MENSAGEM' 
                        value={this.state.mensagemForm}
                        onChange={this.onChange}
                        required 
                        minLength="30"></textarea>
                </div>       
                <button 
                    disabled={this.state.isLoading}
                    className="btn btn-primary button-custom" 
                    type="submit">
                    {this.state.isLoading ? "ENVIANDO..." : "ENVIAR"}
                </button>
                </form>
            </div>
        </div>
    )
  }
}

export default ContatoForm