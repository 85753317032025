import React from "react";
import PrimeiraSecao from './PrimeiraSecao'
import SegundaSecao from './SegundaSecao'
import TerceiraSecao from './TerceiraSecao'
import QuartaSecao from './QuartaSecao'
import QuintaSecao from './QuintaSecao'

import { SalesEnabled } from '../../data/Service/ConfigurationService';
import { Consult } from '../../data/Service/ConfigurationService';

import '../../assets/css/style.css'

class CursoCompleto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            PurchaseEnabled: 'false',
            HabilitarVendaDireta: 'false',
            ArvoreDinamica : 'SIM',
            HabilitarPaginaVendas : 'false',
            TextoBotao : "ADQUIRIR O CURSO COMPLETO!"
        }

        this.getHabilitarVendas = this.getHabilitarVendas.bind(this);
    }

    async getHabilitarVendas() {
        let data = await SalesEnabled().then(response => {
            return response;
        });

        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
            this.setState({
                PurchaseEnabled: data.Dados[0].Valor
            });
        }

         data = await Consult("HABILITAR_VENDA_DIRETA").then(response => {
            return response;
        });
        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
            this.setState({
                HabilitarVendaDireta: data.Dados[0].Valor
            });
        }

        data = await Consult("BOTAO_COMPRAR").then(response => {
            return response;
        });
        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
            this.setState({
                TextoBotao: data.Dados[0].Valor
            });
        }


        data = await Consult("HABILITAR_PAGINA_COMPRAS").then(response => {
            return response;
        });
        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
            this.setState({
                HabilitarPaginaVendas: data.Dados[0].Valor
            });

           
        }
        // data = await Consult("ARVORE_DINAMICA").then(response => {
        //     return response;
        // });
        // if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
        //     this.setState({
        //         ArvoreDinamica: data.Dados[0].Valor
        //     });

           
        // }
    }

  
    componentDidMount() {
        this.getHabilitarVendas();
    }

    render() {
        const { state } = this;

        return (
            <>
                <div id="parallax-world-of-ugg">
                   
                    <div className="parallax-curso-completo" id='conteudo-completo-h'>
                        <div className="d-flex row m-0 align-items-center" style={{paddingTop: '8rem'}}>
                            <div className="col-12 self-align-left m-auto pb-5 pt-5 indexVideo" style={{ textAlign: 'center' }}>
                                <iframe className="pr-2 pl-2 indexVideoIFrame" src="https://www.youtube.com/embed/_5N3GAzx6Ow" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>

                        <div className='container container-quarta-secao'>
                            <QuintaSecao PurchaseEnable={state.PurchaseEnabled} HabilitarVendaDireta= {state.HabilitarVendaDireta}  HabilitarPaginaVendas= {state.HabilitarPaginaVendas}  TextoBotao = {this.state.TextoBotao}/>
                        </div>
                        <div className='container container-primeira-secao mt-5'>
                            <PrimeiraSecao ArvoreDinamica={state.ArvoreDinamica}/>
                        </div>
                        <div className='container container-segunda-secao'>
                            <div className='container content-completo text-curso-completo custom-width' style={{ width: '715px' }}>
                                O QUE INCLUI O CURSO COMPLETO
                            </div>
                            <div className='container container-terceira-secao text-curso'>
                                <SegundaSecao />
                                <TerceiraSecao />
                            </div>
                            <div className='container container-quarta-secao'>
                                <QuartaSecao />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CursoCompleto;