import React, { Component } from 'react';

class TreeHeader extends Component {
    render() {
        return (
            <div className='col-md-4 col-sm-12 col-12 mb-5 content-margin' style={{ padding: '0' }}>
                <div className='container content text-curso-completo-logado'>
                    A ÁRVORE DE ENSINO <br /> PIANO TALKS
                    </div>
                <div className='col-md-12 col-sm-12 col-12' style={{ marginTop: '20px' }}>
                    <div className='text-curso-desc text-center-custom'>
                        <p className='p-texto' style={{ margin: '0 0 0 4px' }}>
                            Escolha a sua aula e bons estudos!
                            </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default TreeHeader;