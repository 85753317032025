import React from 'react';
import Button from '@material-ui/core/Button';
import { validaCupom, getUsers } from './UserFunctions'
import swal from 'sweetalert'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField';
import { PayPalButton } from 'react-paypal-button-v2';
import { EmailAtivacao, Register } from '../../data/Service/UserService';
import { ListAll } from '../../data/Service/ConfigurationService';
import Iframe from 'react-iframe'
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Col, Row, Image, Container } from "react-bootstrap";




export default class ComprarCursoDetalhe extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            detaheCompra: '',
            textoBotao: '',
            vendaDireta: 'false'
        }

        this.getConfiguracoes();
        
    }

    async getConfiguracoes() {
        let data = await ListAll().then(response => {
            return response;
        });
    
        if (data.Dados !== undefined && data.Dados !== null && data.Dados.length !== 0) {
    
            this.setState({
                detaheCompra: data.Dados.filter(a => a.Chave == "TEXTO_PAGINA_COMPRAS")[0].Valor
            })

            this.setState({
                textoBotao: data.Dados.filter(a => a.Chave == "BOTAO_COMPRAR")[0].Valor
            })

            this.setState({
                vendaDireta: data.Dados.filter(a => a.Chave == "HABILITAR_VENDA_DIRETA")[0].Valor
            })
        }
    }
       

    render(){
        return (
            <>

<div id='header-h' style={{overflow:'hidden'}}>
            <div className='container container-1'>
                <Row /*style={{borderStyle: 'solid'}}*/>
                    <Col xs={12} sm={12} md={12} style={{padding: '10'}}>
                    <iframe width="560" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" src="https://www.youtube-nocookie.com/embed/fPYgopKYqzo?autoplay=1&amp;mute=1"></iframe>
<div  style={{color:'#fff'}}
                           dangerouslySetInnerHTML={{ __html: this.state.detaheCompra }} />

{
this.state.vendaDireta.toUpperCase() == "SIM" ?
<a color="primary" href="https://api.whatsapp.com/send?1=pt_BR&phone=5519984458777&text=Gostaria%20de%20me%20informar%20melhor%20sobre%20o%20curso" target="_blank" rel="noopener noreferrer" className='col-12 no-decoration' style={{ textAlign: 'center' }} >
<div className='container adquirir-curso' style={{ fontFamily: 'Fira Sans, sans-serif', fontSize: '14px' }}>
{this.state.textoBotao} 
</div>
</a>
                        :
                        <Link to="adquirir-curso" className='col-12 no-decoration' style={{ textAlign: 'center' }}>
                        <div className='adquirir-curso' style={{ fontFamily: 'Fira Sans, sans-serif', fontSize: '14px', paddingBottom:'0px' }}>
                            {this.state.textoBotao}     
                        </div>
                    </Link>
                    }
                        </Col></Row></div></div>



    </>
        )
    }
}