import React from "react";
import ProfessoresConvidados from '../ProfessoresConvidados/index'

import '../../assets/css/style.css'

class Professores extends React.Component {  

    render(){
        return (
            <>
            <div id="parallax-world-of-ugg">
                <div className="parallax-professores" id='professores-h'>
                    <div className='container professores-adjust'>
                        <div className='content-completo-professores text-quem-professores container'>
                            QUEM SÃO OS PROFESSORES?
                        </div>
                        <div className='container'>
                            <div className='row' style={{padding: '25px 0px 0px 0px'}}>
                                <div className='col-md-6 col-sm-12 col-12 edson-padding'>
                                    <div className="show-edson">
                                        <div className="edson-bg-img">
                                            <div className='container' style={{lineHeight: '1px', textAlign: 'center', color: 'white', paddingTop: '350px'}}>
                                                <h3>EDSON SANT'ANNA</h3>
                                            </div>
                                        </div>
                                        <div className="edson-description">
                                            <div className='container' style={{margin: '0', padding: '30px 20px 20px 20px'}}>
                                                <p>Pianista e compositor natural de Santa Catarina, <br />
                                                    nasceu em 1981.  
                                                </p>
                                                <p>
                                                    Estudou piano Popular com <br />
                                                    Evaldo Soares, piano erudito com Marisa Lacorte <br />
                                                    e composição com Almeida Prado.
                                                </p>
                                                <p>
                                                    Como instrumentista é integrante do Trio Ciclos, <br />
                                                    Soundscape Big Band, Daniel D'Alcantara Quinteto <br />
                                                    e Bob Wyatt Quarteto.
                                                </p>
                                                <p>
                                                    Ao longo de sua trajetória vem realizando intensa <br />
                                                    atividade de pesquisa e ensino relacionado ao <br />
                                                    piano e improvisação.
                                                </p>
                                                <p>
                                                    Faz parte do corpo docente do Bacharelado em <br />
                                                    Música da Faculdade Cantareira.
                                                </p>
                                                <p>
                                                    É formado pela Escola Municipal de Música de <br />
                                                    São Paulo, bacharel em composição e mestrando <br />
                                                    em música pela USP - Universidade de São Paulo.
                                                </p>
                                            </div>
                                        </div>                                 
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 col-12 felipe-padding'>
                                    <div className="show-felipe">
                                        <div className="felipe-bg-img">
                                            <div className='container' style={{lineHeight: '1px', textAlign: 'center', color: 'white', paddingTop: '350px'}}>
                                                <h3>FELIPE SILVEIRA</h3>
                                            </div>
                                        </div>
                                        <div className="felipe-description">
                                        <div className='container' style={{margin: '0', padding: '30px 20px 20px 20px'}}>
                                                <p>Pianista e intérprete, professor, arranjador e, <br />
                                                    compositor.  
                                                </p>
                                                <p>
                                                    Músico profissional há mais de 20 anos, estudou <br />
                                                    música popular com Jaime Barbosa durante <br />
                                                    muitos anos e iniciou sua experiência musical e <br />
                                                    profissional acompanhando seu pai, o reconhecido <br />
                                                    músico João Alexandre.
                                                </p>
                                                <p>
                                                    Foi professor convidado da 37ª edição do <br />
                                                    CIVEBRA em Brasília - 2015 e lecionou workshops <br />
                                                    sobre música brasileira na UNT (University North <br />
                                                    of Texas) e Hunter College em Nova Iorque - 2019.
                                                </p>
                                                <p>
                                                    Esteve com nomes de grande expressão na cena <br />
                                                    instrumental mundial como Baptispe Herbin, <br />
                                                    Lukas Pfeil, Jens Lo, Felipe Lamoglia, Irving Acao, <br />
                                                    Ted Nash, Marcus Printup, Chad Lefkowitz, <br />
                                                    Adonis Rose, Woody Witt, Ryan Keberle, Davy <br />
                                                    Mooney, Alison Crocket, Ohad Talmor.
                                                </p>                                                    
                                            </div>
                                        </div>                                 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProfessoresConvidados></ProfessoresConvidados>
                </div>
            </div>
            </>
        );
    }
}

export default Professores;