import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const token = localStorage.getItem('token')
const expirationToken = localStorage.getItem('expiration-token')
const expirationTime = (expirationToken * 1000) - 60000
if(Date.now() >= expirationTime){
    localStorage.clear()
}

export const PrivateRoute = ({component: Component, ...rest}) => {
    return (            
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page        
        <Route {...rest} 
            render={props => (
                token ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;