import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faVideo, faFilePdf, faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";

class TreeBranch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalData: [],
            showModal: false,
            videoData: [],
            showVideo: false
        };

        this.modalControl = this.modalControl.bind(this);
        this.videoControl = this.videoControl.bind(this);
    }

    static defaultProps = {
        title: '',
        text: '',
        branchNumber: '',
        ariaBabelledby: '',
        child: [],
        clicked: false
    }

    videoControl(data) {
        let { state } = this;

        if (!state.showVideo) this.setState({ videoData: data, showVideo: true });
        else this.setState({ showVideo: false })
    }

    modalControl(data) {
        let { state } = this;

        if (!state.showModal) this.setState({ modalData: data, showModal: true });
        else this.setState({ showModal: false })
    }

    render() {
        const { title, text, newContent, openModal, branchNumber, ariaBabelledby, child } = this.props;
        const { state } = this;

        let cont = 0;
        let subCont = 0;
        let subInterno = 0;


        return (
            <>
                <div className={"galho" + branchNumber + ' .tree-fade'}>
                    <div className={branchNumber === 1 ? 'text-center dropdown' : 'text-center dropdown zindex3'}>
                        <a className="dropdown-toggle titulo-arvore mb-1" href="#" role="button" data-toggle="dropdown" id="dropdownMenuLink1" aria-haspopup="true" aria-expanded="false">
                           
                            {title} {newContent === true ? " (New)" : ""}
                            <p className="subtitulo-arvore mb-1">{text}</p>
                        </a>
                        <div className={branchNumber === 1 ? 'drop_arvore dropdown-menu' : 'drop_arvore dropdown-menu zindex3'} aria-labelledby={ariaBabelledby}>
                            <form>
                                <div id={"accordion" + branchNumber}>
                                    {
                                        child.map(dois => {
                                            cont += 1;
                                            return (
                                                <div className="card" key={"indexTree2" + dois.IdArvore}>
                                                    {
                                                        dois.LinkPaginaInicial !== null && dois.LinkPaginaInicial !== '' ?
                                                            <div className="card-header" id={"galho" + branchNumber + "link" + cont}>
                                                                <h5 className="mb-0">
                                                                    <a className="btn btn-link collapsed" href="#" data-toggle="collapse" aria-expanded="true" aria-controls={"collapse" + branchNumber + "link" + cont}
                                                                        onClick={() => this.videoControl(dois)}>
                                                                        
                                                                        {dois.Titulo} { dois.New === true ? " (New)" : ""}
                                                                        <p className=".subtitulo-branch mb-1">{dois.SubTitulo}</p>
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="card-header" id={"galho" + branchNumber + "link" + cont}>
                                                                    <h5 className="mb-0">
                                                                        <a className="btn btn-link collapsed" href="#" data-toggle="collapse" data-target={"#collapse" + branchNumber + "link" + cont} aria-expanded="true" aria-controls={"collapse" + branchNumber + "link" + cont}>
                                                                           
                                                                            {dois.Titulo} { dois.New === true ? " (New)" : ""}
                                                                            <p className=".subtitulo-branch mb-1">{dois.SubTitulo}</p>
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                                <div id={"collapse" + branchNumber + "link" + cont} className="collapse" aria-labelledby={"galho" + branchNumber + "link" + cont} data-parent="#accordion1">
                                                                    {
                                                                        dois.Filho.map(tres => {
                                                                            subCont += 1;
                                                                            return (
                                                                                <div className="card-body collapse2" key={"indexTree3" + tres.IdArvore}>
                                                                                    <div id={"accordion" + branchNumber + "internolink" + subCont}>
                                                                                        <div className="card mb-2">
                                                                                            {
                                                                                                tres.LinkPaginaInicial !== null && tres.LinkPaginaInicial !== '' ?
                                                                                                    <div className="card-header" id={"accordion" + branchNumber + "internolink" + cont + "-" + subCont}>
                                                                                                        <h5 className="mb-0">
                                                                                                            <a className="btn btn-link collapsed" href="#" data-toggle="collapse" aria-expanded="true" aria-controls={"accordion" + branchNumber + "terceironivellink" + cont + "-" + subCont}
                                                                                                                onClick={() => this.videoControl(tres)}>
                                                                                                               
                                                                                                                {tres.Titulo } {tres.New === true ? " (New)" : ""}
                                                                                                                <p className="subtitulo-branch mb-1">{tres.SubTitulo}</p>
                                                                                                            </a>
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className="card-header" id={"accordion" + branchNumber + "internolink" + cont + "-" + subCont}>
                                                                                                            <h5 className="mb-0">
                                                                                                                <a className="btn btn-link collapsed" href="#" data-toggle="collapse" data-target={"#accordion" + branchNumber + "terceironivellink" + cont + "-" + subCont} aria-expanded="true" aria-controls={"accordion" + branchNumber + "terceironivellink" + cont + "-" + subCont}>
                                                                                                                   
                                                                                                                    {tres.Titulo } {tres.New === true ? " (New)" : ""}
                                                                                                                    <p className="subtitulo-branch mb-1">{tres.SubTitulo}</p>
                                                                                                                </a>
                                                                                                            </h5>
                                                                                                        </div>
                                                                                                        <div id={"accordion" + branchNumber + "terceironivellink" + cont + "-" + subCont} className="collapse" aria-labelledby={"accordion" + branchNumber + "internolink" + cont + "-" + subCont} data-parent={"#accordion" + branchNumber + "internolink" + cont}>
                                                                                                            <div className="card-body">
                                                                                                                <div className="list-group text-center">
                                                                                                                    {
                                                                                                                        tres.Filho.map(quatro => {
                                                                                                                            subInterno += 1;
                                                                                                                            return (
                                                                                                                                <a className="list-group-item list-group-item-action mousekey" key={"indexTree4" + quatro.IdArvore} onClick={() => this.videoControl(quatro)}>
                                                                                                                                    {quatro.Titulo}
                                                                                                                                    <p className="subtitulo-branch mb-1">{quatro.SubTitulo}</p>
                                                                                                                                </a>
                                                                                                                            );
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
                

            </>
        );
    }
}

export default TreeBranch;