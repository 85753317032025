import { Get, Post } from './BaseService';

const url = 'https://pianotalksapi.gruposch.com.br/api/Usuario';

const apiToken = 'UpcXW3435oWhj9889kl4';

export async function Register (nome, email, senha, cpf, celular, cupom, valor) {
    const endpoint = '/Cadastro?nome='+ nome + '&email=' + email + '&celular=' + celular + '&cpf='+cpf+'&senha='+senha+'&valor='+valor+'&cupom='+cupom+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(url + endpoint, headers);
}

export async function EmailAtivacao (email, nome, senha, tokenValidacao) {
    const endpoint = '/EmailAtivacao?email='+ email + '&nome=' + nome + '&senha=' + senha + '&tokenValidacao=' + tokenValidacao + '&token=' + apiToken;
    
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(url + endpoint, headers);
}

export async function EsqueciMinhaSenha (email, senha, tokenValidacao) {
    const endpoint = '/EsqueciMinhaSenha?email='+ email + '&senha=' + senha + '&tokenValidacao=' + tokenValidacao + '&token=' + apiToken;
   
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };
    
    return await Get(url + endpoint, headers);
}

export async function EnviarEmailSenha (email) {
    const endpoint = '/EnviarEmailSenha?email='+ email+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(url + endpoint, headers);
}
export async function ValiedAccount (email, senha, sucesso) {
    const endpoint = '/ValiedAccount?email='+ email+'&senha=' + senha+'&token=' + apiToken+'&sucesso=' + sucesso;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(url + endpoint, headers);
}


export async function RequestValidation (request) {

    const endpoint = '/RequestValidation?request='+ request+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };
    var data = await Get(url + endpoint, headers);
    return data;
}

export async function SearchUser(email, password) {
    email = email.replace('@', '%40');

    const endpoint = '/SearchUser?email=' + email + '&senha=' + password+'&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    var teste = await Get(url + endpoint, headers);
    return teste;
}


export async function UpdateUser(user) {

    var     email = user.email.replace('@', '%40');
var tokenValidation=localStorage.getItem('token');
    const endpoint = '/alter?codigo='+user.id+'&nome='+user.nome+'&email=' + email + '&senha=' + user.senha+'&documento='+user.cpf+'&telefone='+user.celular+'&dataNascimento='+user.data_nascimento+'&cep='+user.cep+'&token='+ apiToken+'&tokenValidation='+tokenValidation;
    console.info(endpoint)
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    var teste = await Get(url + endpoint, headers);
    return teste;
}