import React from "react";
import '../../assets/css/style.css'

const convidados = {
    amilton: require('../../assets/img/entrevistados/ENTREVISTA-AMILTON.jpg').default,
    ehud: require('../../assets/img/entrevistados/ENTREVISTA-EHUD.jpeg').default,
    evaldo: require('../../assets/img/entrevistados/ENTREVISTA-EVALDO.jpeg').default,
    nelson: require('../../assets/img/entrevistados/ENTREVISTA-NELSON.jpg').default,
    otmaro: require('../../assets/img/entrevistados/ENTREVISTA-OTMARO.jpg').default,
    luiz: require('../../assets/img/entrevistados/ENTREVISTA-LUIZ.jpeg').default
  }
class TerceiraSecao extends React.Component {

  render(){

        return (
            <>
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center'}}>
                    <p className='o-que-desc text-align-custom'>
                        5. ENTREVISTAS COM PIANISTAS RENOMADOS
                    </p>
                    <p className='o-que-desc-sub text-align-custom'>
                        Você terá acesso a entrevistas em vídeo com pianistas renomados
                        nacionais e internacionais! <br />
                        Veja quem já entrevistamos:
                    </p>
                </div>
            </div>
            <div className='row' style={{marginTop: '20px'}}>
                <div className='col-md-4 col-sm-4 col-12' style={{textAlign: 'center'}}>
                    <div className='card border-convidados-img' >
                        <img src={convidados.otmaro} className='card-img-top'/>
                        <div className='card-body'>
                            <p className='o-que-desc text-align-custom'>
                                OTMARO RUIZ/LOS ANGELES
                            </p>
                            <hr className='hr-custom'/>
                            <p className='o-que-desc-sub text-align-custom'>
                                Tocou com Arturo Sandoval,
                                Dianne Reeves, Frank Gambale e 
                                Lee Ritenour
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-sm-4 col-12' style={{textAlign: 'center', marginTop: '10px'}}>
                    <div className='card border-convidados-img'>
                        <img src={convidados.nelson} className='card-img-top'/>
                        <div className='card-body'>
                            <p className='o-que-desc text-align-custom'>
                                NELSON AYRES/SÃO PAULO
                            </p>
                            <hr className='hr-custom'/>
                            <p className='o-que-desc-sub text-align-custom'>
                                Integrante do Grupo Pau Brasil.
                                Tocou com Ron Carter, Dizzy 
                                Gillespie, Vitor Assis Brasil e foi 
                                arranjador e regente da 
                                Jazz Sinfônica de São Paulo
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-sm-4 col-12' style={{textAlign: 'center'}}>
                    <div className='card border-convidados-img'>
                        <img src={convidados.amilton} className='card-img-top'/>
                        <div className='card-body'>
                            <p className='o-que-desc text-align-custom'>
                                AMILTON GODOY/SÃO PAULO
                            </p>
                            <hr className='hr-custom'/>
                            <p className='o-que-desc-sub text-align-custom'>
                                Tocou com Zimbo Trio, Elis Regina,
                                Elizabeth Cardoso, Wilson Simonal e 
                                Hector Costita
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container' style={{marginTop: '20px'}}>
                <div className='row'>
                    <div className='col-7'>
                        <div className='card mb-3' style={{borderRadius: '0px', border: '3px solid', marginLeft: '0px', backgroundColor: 'transparent'}}>
                            <div className='row no-gutters'>
                                <div className='col-md-5'>
                                    <img src={convidados.evaldo} style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className='col-md-7'>
                                    <div className='card-body p-2'>
                                        <h5 className='card-title'>
                                            <p className='o-que-desc m-0 text-align-custom'>
                                                EVALDO SOARES/SÃO PAULO
                                            </p>
                                        </h5>
                                        <hr className='hr-custom-2'/>
                                        <p className='o-que-desc-sub text-align-custom'>
                                            Tocou com Tito Madi, Dori Caimmy, Pery Ribeiro e Sílvio César
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className='col-5' style={{marginTop: '50px'}}>
                        <div className='card mb-3' style={{borderRadius: '0px', border: '3px solid', marginLeft: '0px', backgroundColor: 'transparent'}}>
                            <div className='row no-gutters'>
                                <div className='col-md-5'>
                                    <img src={convidados.ehud} style={{width: '100%'}}/>
                                </div>
                                <div className='col-md-7'>
                                    <div className='card-body p-2'>
                                        <h5 className='card-title'>
                                            <p className='o-que-desc m-0 text-align-custom'>
                                                EHUD ASHERIE/NOVA IORQUE
                                            </p>
                                        </h5>
                                        <hr className='hr-custom-2'/>
                                        <p className='o-que-desc-sub text-align-custom'>
                                            Tocou com Eric Alexander, Peter Bernstein, Clark Terry e Scott Hamilton
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>                                        
                </div>
                <div className='row' style={{marginTop: '-81px'}}>
                    <div className='col-7'>
                        <div className='card mb-3' style={{borderRadius: '0px', border: '3px solid', marginLeft: '0px', backgroundColor: 'transparent'}}>
                            <div className='row no-gutters'>
                                <div className='col-md-5'>
                                    <img src={convidados.luiz} style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className='col-md-7'>
                                    <div className='card-body p-2'>
                                        <h5 className='card-title'>
                                            <p className='o-que-desc m-0 text-align-custom'>
                                                LUIZ MELLO/SÃO PAULO
                                            </p>
                                        </h5>
                                        <hr className='hr-custom-2'/>
                                        <p className='o-que-desc-sub text-align-custom'>
                                            Tocou com SamBossa 5, Milton Banana, Ray Brown, Jo Jones e Jim Hall
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default TerceiraSecao;