import { Get } from './BaseService';

const url = 'https://pianotalksapi.gruposch.com.br/api/Arvore';

const apiToken = 'UpcXW3435oWhj9889kl4';

export async function CompleteTree (languageId, email) {
    email = email.replace('@', '%40');
    const endpoint = '/ArvoreCompleta?idiomaId=' + languageId + "&emailUsuario=" + email  + '&token=' + apiToken;
    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

   return await Get(url + endpoint, headers);
}

export async function GetIndexTree (languageId) {
    const endpoint = '/ArvoreIndex?idiomaId=' + languageId + '&token=' + apiToken;

    const headers = { 
        'Accept': 'application/json',
        "Access-Control-Allow-Headers" : "Content-Type"
    };

    return await Get(url + endpoint, headers);
}